import {
  CircularProgress,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import Timer from 'react-timer-wrapper';
import Timecode from 'react-timecode';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import React, { useEffect, useState } from 'react';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgressWithLabel } from './CircularProgressWithLabel';
import { useHttp } from '../hooks/useHttp';
import { useDispatch, useSelector } from 'react-redux';
import { removeFile } from '../actions/files';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import { uploadFile } from '../services/CloudService';
import { addActiveId, removeActiveId } from '../features/files/filesSlice';

const maxFiles = 12;

export const UploadFileItem = ({
  id,
  file,
  path,
  reportId,
  folder,
  isCompress = false,
}) => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const [activeCounter, setActiveCounter] = useState(false);
  const { files, activesId } = useSelector((state) => state.files);
  const [errorMessage, setErrorMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [reTryNumber, setReTryNumber] = useState(0);
  const { request, loading, error } = useHttp(uploadFile, {
    id: reportId,
    file,
    path,
    folder,
    isCompress,
    onUploadProgress: (p) => setProgress((p.loaded * 100) / p.total),
  });

  const handleUpload = async () => {
    setActiveCounter(true);
    const result = await request();
    if (!result) return;
    setActiveCounter(false);
    setTimeout(() => {
      dispatch(removeActiveId(id));
      dispatch(removeFile(id));
    }, 1500);
  };

  const handleChangeActiveId = () => {
    if (activesId.length >= maxFiles) return;
    if (!files.find((item) => item.id === id)) return;
    if (activesId.includes(id)) return;
    dispatch(addActiveId(id));
    handleUpload();
  };

  const handleRemove = () => {
    dispatch(removeFile(id));
  };

  const getMessage = () => {
    if (error) {
      return errorMessage;
    }

    if (progress >= 100) {
      return `${t('cloud.labels.proccessing_files')}...`;
    }
    return '';
  };

  useEffect(() => {
    handleChangeActiveId();
  }, [activesId]);

  useEffect(() => {
    if (error && reTryNumber < 3) {
      const data = error.response.data;
      const message = data.message.toLowerCase();
      if (!message.includes('enough space')) {
        setReTryNumber(reTryNumber + 1);
        setErrorMessage(t('cloud.modal_error.save'));
        handleUpload();
      } else {
        setErrorMessage(t('cloud.modal_error.space'));
        setReTryNumber(3);
      }
    }
  }, [error]);

  return (
    <>
      <ListItem alignItems='flex-start' sx={{ gap: 2, alignItems: 'center' }}>
        <ListItemAvatar>
          <InsertDriveFileIcon />
        </ListItemAvatar>

        <Stack
          sx={{
            width: '90%',
          }}
        >
          <Typography variant='body1' noWrap>
            {file.name}
          </Typography>
          <Typography variant='caption'>{getMessage()}</Typography>
        </Stack>

        {activeCounter && !Boolean(error) && (
          <Timer active={activeCounter} duration={null}>
            <Timecode />
          </Timer>
        )}

        {progress < 100 && <CircularProgressWithLabel value={progress} />}
        {progress >= 100 && !error && !loading && (
          <CheckCircleOutlinedIcon color='success' fontSize='large' />
        )}
        {progress >= 100 && loading && <CircularProgress />}
        {error && (
          <Box display='flex' alignContent='center'>
            <ErrorIcon color='warning' fontSize='large' />
            <IconButton onClick={handleRemove}>
              <CloseIcon color='error' />
            </IconButton>
          </Box>
        )}
      </ListItem>
      <Divider />
    </>
  );
};
