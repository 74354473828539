import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

const cleanCustom = (value) => {
  const copy = { ...value };
  delete copy.isCompress;
  return copy;
};

export const CircularProgressWithLabel = (props) => {
  const { value, isCompress } = props;
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant='determinate' {...cleanCustom(props)} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {isCompress && (
          <FontAwesomeIcon
            icon={faBoxOpen}
            style={{ fontSize: '0.6rem' }}
            className='animate__animated animate__flash animate__slower animate__infinite'
          />
        )}
        <Typography variant='caption' component='div'>
          {value ? `${Math.round(value)}%` : ''}
        </Typography>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
  isCompress: PropTypes.bool,
};
