import { Navigate, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
const CollaboratorsPage = loadable(
  () => import('../../pages/Collaborators/CollaboratorsPage'),
  {
    resolveComponent: (components) => components.CollaboratorsPage,
  },
);

const CollaboratorsCreateUpdatePage = loadable(
  () =>
    import(
      '../../pages/CollaboratorsCreateUpdate/CollaboratorsCreateUpdatePage'
    ),
  {
    resolveComponent: (components) => components.CollaboratorsCreateUpdatePage,
  },
);

const CollaboratorDetailsPage = loadable(
  () => import('../../pages/CollaboratorDetails/CollaboratorDetailsPage'),
  {
    resolveComponent: (components) => components.CollaboratorDetailsPage,
  },
);

export const CollaboratorsRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<CollaboratorsPage />} />
      <Route path='/create' element={<CollaboratorsCreateUpdatePage />} />
      <Route path='/edit/:id' element={<CollaboratorsCreateUpdatePage />} />
      <Route path='/:id' element={<CollaboratorDetailsPage />} />
      <Route path='/*' element={<Navigate to='/' />} />
    </Routes>
  );
};
