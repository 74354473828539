import { environment } from '../environment/environment';
import queryString from 'query-string';
import { headersAutorization } from '../helpers/headerAutorization';
import http from '../helpers/http';

const url = environment.apiCloud;

export const uploadFile = ({
  id,
  file,
  path,
  folder,
  isCompress,
  onUploadProgress,
}) => {
  const params = {
    path,
    folder,
    compress: isCompress,
  };
  const query = queryString.stringify(params);
  const formData = new FormData();
  formData.append('files', file, file.name);
  const headers = headersAutorization();
  return http.postDataAxios(
    `${url}/interpretation/${id}/files/upload?${query}`,
    formData,
    headers,
    onUploadProgress,
  );
};

export const deleteFile = ({ id, path, fileName }) => {
  const headers = headersAutorization();
  return http.remove(
    `${url}/interpretation/${id}/files/delete?path=${encodeURI(
      path,
    )}&filename=${encodeURIComponent(fileName)}`,
    headers,
  );
};

export const downloadFile = ({ url, onDownloadProgress, controller }) => {
  return http.downloadFile(url, {}, onDownloadProgress, controller);
};
