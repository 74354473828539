import * as React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useTranslation } from 'react-i18next';
import 'moment/dist/locale/es-mx';
import 'moment/dist/locale/fr-ch';
import 'moment/dist/locale/pt-br';

const locationMap = {
  es: 'es-mx',
  fr: 'fr-ch',
  pt: 'pt-br',
};

export const LocalizationWrapper = ({ children }) => {
  const [_, i18n] = useTranslation('common');
  const { language } = i18n;
  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale={locationMap[language]}
    >
      {children}
    </LocalizationProvider>
  );
};
