import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const AlertReOpen = ({
  open,
  onClose,
  severity = 'success',
  message = 'reusable.general_error',
  sx = {},
}) => {
  const [t] = useTranslation('common');
  const content = message === 'reusable.general_error' ? t(message) : message;

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Collapse in={open}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label='close'
              color='inherit'
              size='small'
              onClick={onClose}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {content}
        </Alert>
      </Collapse>
    </Box>
  );
};

AlertReOpen.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
