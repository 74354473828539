import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { AppRouter } from './routes/AppRouter';
import { store } from './store/store';
import common_en from './i18n/en.json';
import common_es from './i18n/es.json';
import common_fr from './i18n/fr.json';
import common_pt from './i18n/pt.json';
import { userLanguage } from './utils/language';
import { configTheme } from './theme/configTheme';
import { SocketProvider } from './context/SocketContext';
import { useEffect, useMemo } from 'react';
import { LocalizationWrapper } from './LocalizationWrapper';
import { SnackbarProvider } from 'notistack';
import 'swiper/css/bundle';
import 'swiper/scss';
import 'swiper/scss/zoom';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/css/grid';
import { initCornerstone } from './initCornerstone';

export const TeleimagenologiaApp = () => {
  const language = userLanguage();
  document.documentElement.lang = language;
  i18next.init({
    interpolation: { escapeValue: true },
    lng: language,
    resources: {
      en: {
        common: common_en,
      },
      es: {
        common: common_es,
      },
      fr: {
        common: common_fr,
      },
      pt: {
        common: common_pt,
      },
    },
  });

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(
    () => createTheme(configTheme(prefersDarkMode)),
    [prefersDarkMode],
  );
  useEffect(() => {
    initCornerstone();
  }, []);

  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={3}>
        <SocketProvider>
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18next}>
              <LocalizationWrapper>
                <AppRouter />
              </LocalizationWrapper>
            </I18nextProvider>
          </ThemeProvider>
        </SocketProvider>
      </SnackbarProvider>
    </Provider>
  );
};
