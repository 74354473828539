import React, { useState } from 'react';
import {
	CssBaseline,
	Divider,
	IconButton,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
	Toolbar,
	Stack,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/system';
import { menuList } from '../menuList';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { UserItem } from './UserItem';
import MenuAppBar from './MenuAppBar';
import { AdsComponent } from '../../components/AdsComponent';
import { ListLogout } from './ListLogout';
import { setIsBottom } from '../../features/ui/uiSlice';
const drawerWidth = 300;
const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});
const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

export const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export const MenuDrawer = ({ children, window }) => {
	const [t] = useTranslation('common');
	const theme = useTheme();
	const dispatch = useDispatch();
	const [mobileOpen, setMobileOpen] = useState(false);
	const { userData } = useSelector((state) => state.auth);
	const user = userData.user;
	const { pathname } = useLocation();
	const [open, setOpen] = useState(false);
	const container =
		window !== undefined ? () => window().document.body : undefined;
	const iOS =
		typeof navigator !== 'undefined' &&
		/iPad|iPhone|iPod/.test(navigator.userAgent);
	const handleScroll = ({ target }) => {
		const bottom =
			target.scrollHeight - target.scrollTop === target.clientHeight;
		dispatch(setIsBottom(bottom));
	};
	const handleDrawerToggle = () => {
		handleDrawerClose();
		setMobileOpen(!mobileOpen);
	};

	const handleChangeStatusMobile = (value) => setMobileOpen(value);

	const handleDrawerOpen = () => {
		if (!open) setOpen(true);
	};

	const handleDrawerClose = () => {
		if (open) setOpen(false);
	};

	const drawer = (
		<List
			sx={{
				pt: 'env(safe-area-inset-top)',
			}}
		>
			<UserItem
				photo={user.photo}
				name={user.name}
				lastName={user.last_name}
				username={user.username}
				online={user.online}
				mobileOpen={mobileOpen}
				handleDrawerOpen={handleDrawerOpen}
				openDesktop={open}
				handleDrawerClose={handleDrawerClose}
			/>
			{menuList.map(({ name, path, icon }) => (
				<ListItemButton
					key={name}
					component={NavLink}
					to={path}
					selected={pathname === path}
					onClick={handleDrawerClose}
					sx={{
						minHeight: 48,
						justifyContent: open ? 'initial' : 'center',
						px: 2.5,
					}}
				>
					<ListItemIcon
						sx={{
							minWidth: 0,
							mr: open ? 3 : 'auto',
							justifyContent: 'center',
						}}
					>
						{icon}
					</ListItemIcon>
					<ListItemText sx={{ opacity: open ? 1 : 0 }} primary={t(name)} />
				</ListItemButton>
			))}
		</List>
	);

	const drawerMobile = (
		<div>
			<Toolbar />
			<Divider />
			<List>
				<UserItem
					photo={user.photo}
					name={user.name}
					lastName={user.last_name}
					username={user.username}
					online={user.online}
					mobileOpen={mobileOpen}
					handleDrawerOpen={handleDrawerOpen}
					handleChangeStatusMobile={handleChangeStatusMobile}
				/>
				{menuList.map(({ name, path, icon }) => (
					<ListItemButton
						key={name}
						onClick={handleDrawerToggle}
						component={NavLink}
						to={path}
						selected={pathname === path}
					>
						<ListItemIcon>{icon}</ListItemIcon>
						<ListItemText primary={t(name)} />
					</ListItemButton>
				))}
			</List>
		</div>
	);

	return (
		<Box sx={{ display: 'flex', minHeight: '100vh', width: '100vw' }}>
			<CssBaseline />
			<MenuAppBar
				mobileOpen={mobileOpen}
				setMobileOpen={setMobileOpen}
				drawerWidth={drawerWidth}
				handleDrawerOpen={handleDrawerOpen}
				open={open}
			/>

			<SwipeableDrawer
				disableBackdropTransition={!iOS}
				disableDiscovery={iOS}
				anchor='left'
				container={container}
				open={mobileOpen}
				onClose={handleDrawerToggle}
				onOpen={handleDrawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: drawerWidth,
					},
				}}
			>
				{drawerMobile}
				<Box sx={{ flexGrow: 1 }}></Box>
				<ListLogout />
			</SwipeableDrawer>
			<Drawer
				variant='permanent'
				open={open}
				sx={{
					display: { xs: 'none', sm: 'flex' },
				}}
			>
				<DrawerHeader>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</DrawerHeader>
				<Divider />
				{drawer}
				<Box sx={{ flexGrow: 1 }}></Box>
				<ListLogout />
			</Drawer>
			<Box
				id='main-content'
				onScroll={handleScroll}
				component='main'
				onClick={() => {
					if (open) handleDrawerClose();
				}}
				sx={{
					width: '100vw',
					minHeight: '100%',
					display: 'flex',
					flexDirection: 'column',
					overflowX: {
						md: 'hidden',
					},
				}}
			>
				<DrawerHeader />
				{user.viewAds && (
					<div
						style={{
							width: '100%',
							height: '50px',
							paddingTop: 'env(safe-area-inset-top)',
						}}
					>
						<AdsComponent dataAdSlot='3054464203' />
					</div>
				)}
				{children}
			</Box>
		</Box>
	);
};
