import { Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import logoTeleimm from '../../../assets/logo.webp';

export const LogoContainer = () => {
  const [t] = useTranslation('common');
  return (
    <Paper
      sx={{
        width: '60%',
        bgcolor: 'infoTeleimm.dark',
        padding: '1rem',
        display: {
          xs: 'none',
          sm: 'none',
          md: 'none',
          lg: 'flex',
          xl: 'flex',
        },
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img src={logoTeleimm} alt='Logo Teleimm' className='auth-logo' />
      <Typography variant='h5' align='center' sx={{ color: 'white' }}>
        {t('app_description')}
      </Typography>
    </Paper>
  );
};
