import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import useAppBarHeight from '../helpers/useAppBarHeight';

export const BoxPage = ({
  children,
  resizeDesktop,
  toolbar = true,
  sx: style,
  styleBoxPageResize = {},
  ...args
}) => {
  const heightToolbar = useAppBarHeight();
  const { userData } = useSelector((state) => state.auth);
  const { viewAds } = userData.user;

  if (resizeDesktop && toolbar)
    return (
      <Box
        className='animate__animated animate__fadeIn'
        display='flex'
        justifyContent='center'
        sx={{
          maxWidth: '100vw',
          height: viewAds
            ? 'calc(100vh - 150px)'
            : `calc(100vh - ${heightToolbar}px)`,
          p: 'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
          m: 1,
          overflowY: 'auto',
          ...style,
        }}
        {...args}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '80vw',
              xl: '80vw',
            },

            ...styleBoxPageResize,
          }}
        >
          {children}
        </Box>
      </Box>
    );

  if (resizeDesktop && !toolbar)
    return (
      <Box
        className='animate__animated animate__fadeIn'
        display='flex'
        justifyContent='center'
        sx={{
          maxWidth: '100vw',
          p: 'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
          m: 1,
          overflowY: 'auto',
          ...style,
        }}
        {...args}
      >
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '80vw',
              xl: '80vw',
            },

            ...styleBoxPageResize,
          }}
        >
          {children}
        </Box>
      </Box>
    );
  return (
    <Box
      className='animate__animated animate__fadeIn'
      sx={{
        maxWidth: '100vw',
        height: viewAds
          ? 'calc(100vh - 150px)'
          : `calc(100vh - ${heightToolbar}px)`,
        p: 'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        m: 1,
        overflowY: 'auto',
        ...style,
      }}
      {...args}
    >
      {children}
    </Box>
  );
};
