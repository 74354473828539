import { createSlice } from '@reduxjs/toolkit';

const initalState = {
  spaceUse: {
    value: 0,
    unit: 'GB',
  },
  spaceTotal: {
    value: 0,
    unit: 'GB',
  },
};

export const cloudSpaceSlice = createSlice({
  name: 'cloudSpace',
  initialState: initalState,
  reducers: {
    updateSpaceUse: (state, action) => {
      return {
        ...state,
        spaceUse: {
          ...action.payload,
        },
      };
    },
    updateSpaceTotal: (state, action) => {
      return {
        ...state,
        spaceTotal: {
          ...action.payload,
        },
      };
    },
    setDataSpace: (_, action) => {
      return {
        ...action.payload,
      };
    },
    removeData: () => {
      return initalState;
    },
  },
});

export const { updateSpaceUse, updateSpaceTotal, setDataSpace, removeData } =
  cloudSpaceSlice.actions;

export default cloudSpaceSlice.reducer;
