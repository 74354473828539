import { menuList } from '../ui/menuList';

export const HomePageHelper = (pathName) => {
  const menuItem = menuList.find(({ path }) => pathName === path);
  if (menuItem) {
    if (menuItem.home && menuItem.path === pathName) return true;
  }
  if (!pathName.includes('all')) return false;
  if (pathName === '/settings') return true;
  return true;
};
