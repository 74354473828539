import {
  CircularProgress,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import FolderIcon from '@mui/icons-material/Folder';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addActiveId, removeActiveId } from '../features/files/filesSlice';
import { removeFile } from '../actions/files';
const maxFiles = 12;

export const UploadFolderNameItem = ({ id, folderName }) => {
  const { files, activesId } = useSelector((state) => state.files);
  const dispatch = useDispatch();
  const [t] = useTranslation('common');
  const isActive = activesId.includes(id);

  const handleUpload = () => {
    setTimeout(() => {
      dispatch(removeActiveId(id));
      dispatch(removeFile(id));
    }, 1000);
  };

  const handleChangeActiveId = () => {
    if (activesId.length >= maxFiles) return;
    if (!files.find((item) => item.id === id)) return;
    if (activesId.includes(id)) return;
    dispatch(addActiveId(id));
    handleUpload();
  };

  useEffect(() => {
    handleChangeActiveId();
  }, [activesId]);

  return (
    <>
      <ListItem alignItems='flex-start' sx={{ gap: 2 }}>
        <ListItemAvatar>
          <FolderIcon />
        </ListItemAvatar>
        <Stack
          sx={{
            width: {
              xs: '70%',
              sm: '70%',
              md: '55%',
            },
          }}
        >
          <Typography variant='body1' noWrap>
            {folderName}
          </Typography>
          <Typography variant='caption'>
            {isActive ? t('cloud.modal_success.new_folder') : ''}
          </Typography>
        </Stack>

        {isActive ? (
          <CheckCircleOutlinedIcon color='success' fontSize='large' />
        ) : (
          <CircularProgress />
        )}
      </ListItem>
      <Divider />
    </>
  );
};
