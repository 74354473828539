import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countTime } from '../helpers/countTime';

export const useTimePass = (lng, timestamp) => {
  const [_, i18n] = useTranslation('common');
  const [time, setTime] = useState(countTime(lng ?? i18n.language, timestamp));

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(countTime(lng, timestamp));
    }, 1000);
    return () => clearInterval(timer);
  }, [timestamp]);

  return time;
};
