import convertSize from 'convert-size';
import CloudIcon from '@mui/icons-material/Cloud';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { Divider, LinearProgress, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { SocketContext } from '../../context/SocketContext';
import { userSocket } from '../../types/sockets';
import { updateSpaceUse } from '../../features/cloudSpace/cloudSpaceSlice';

export const UserSpace = () => {
  const { socket } = useContext(SocketContext);
  const dispatch = useDispatch();
  const { spaceTotal, spaceUse } = useSelector((state) => state.cloudSpace);
  const spaceInUse = spaceUse.value;
  const spaceTotalPlan = spaceTotal.value;
  const currentUse = Math.round((spaceInUse / spaceTotalPlan) * 100);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const [t] = useTranslation('common');

  useEffect(() => {
    socket.on(userSocket.updateSpaceUsed, ({ spaceUsed }) => {
      dispatch(
        updateSpaceUse({
          value: spaceUsed.size,
          unit: spaceUsed.unit,
        }),
      );
    });

    return () => {
      socket.off(userSocket.updateSpaceUsed);
    };
  }, [socket]);

  if (phone)
    return (
      <>
        <ListItem>
          <ListItemIcon>
            <CloudIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('sidebar.storage')}
            secondary={
              <>
                <LinearProgress
                  color={currentUse > 80 ? 'error' : 'primary'}
                  variant={
                    spaceTotalPlan != 0 ? 'determinate' : 'indeterminate'
                  }
                  value={currentUse}
                />
                {spaceTotalPlan != 0 && (
                  <span>{`${spaceInUse} GB ${t(
                    'reusable.of',
                  )} ${spaceTotalPlan} GB`}</span>
                )}
              </>
            }
          />
        </ListItem>
        <Divider />
      </>
    );

  return (
    <>
      <ListItem
        sx={{
          minHeight: 48,
          justifyContent: 'initial',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 3,
            justifyContent: 'center',
          }}
        >
          <CloudIcon />
        </ListItemIcon>
        <ListItemText
          primary={t('sidebar.storage')}
          secondary={
            <>
              <LinearProgress
                color={currentUse > 80 ? 'error' : 'primary'}
                variant={spaceTotalPlan != 0 ? 'determinate' : 'indeterminate'}
                value={currentUse}
              />
              {spaceTotalPlan != 0 && (
                <span>{`${spaceInUse} GB ${t(
                  'reusable.of',
                )} ${spaceTotalPlan} GB`}</span>
              )}
            </>
          }
        />
      </ListItem>
      <Divider />
    </>
  );
};
