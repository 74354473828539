import axios from 'axios';

const get = (url, headers = {}, others = {}) => {
  return axios.get(url, {
    ...others,
    headers: {
      ...headers,
    },
  });
};

const post = (url, data, headers = {}, others = {}) => {
  return axios.post(url, data, {
    ...others,
    headers: {
      ...headers,
    },
  });
};

const postData = (url, data, headers = {}) => {
  return axios.post(url, data, {
    headers: {
      ...headers,
    },
  });
};

const put = (url, data, headers = {}) => {
  return axios.put(url, data, {
    headers: {
      ...headers,
    },
  });
};

const patch = (url, data, headers = {}) => {
  return axios.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const patchData = (url, data, headers = {}) => {
  return axios.patch(url, data, {
    headers: {
      ...headers,
    },
  });
};

const remove = async (url, headers = {}) => {
  return axios.delete(url, {
    headers: {
      ...headers,
    },
  });
};

const postDataAxios = (
  url,
  data,
  headers = {},
  onUploadProgress = () => {},
) => {
  return axios.post(url, data, {
    headers,
    onUploadProgress,
  });
};

const putFiles = (url, data, headers = {}) => {
  return axios.put(url, data, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const patchDataAxios = (
  url,
  data,
  headers = {},
  onUploadProgress = () => {},
) => {
  return axios.patch(url, data, {
    headers,
    onUploadProgress,
  });
};

const downloadFile = (url, headers = {}, onDownloadProgress, controller) => {
  return axios.get(url, {
    headers,
    responseType: 'blob',
    onDownloadProgress: onDownloadProgress || (() => {}),
    signal: controller ? controller.signal : null,
  });
};

const http = {
  get,
  post,
  postData,
  put,
  patch,
  patchData,
  remove,
  postDataAxios,
  patchDataAxios,
  downloadFile,
  putFiles,
};

export default http;
