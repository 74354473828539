export const types = {
  login: '[Auth] Login',
  logout: '[Auth] Logout',
  authCheckingFinish: '[Auth] Finish checking login state',
  setDataReport: '[Report] Set data report',
  setFilesPatientServer: '[Report] Set files patient server',
  updateUserData: '[Auth] Update UserData',
  setRemoveFilesPatientServer: '[Report] Set Remove files patient server',
  setFilesPatient: '[Report] Set files patient',
  setExtraData: '[Report] Set extra data',
  setErrorReport: '[Report] Set error report',
  cleanErrorReport: '[Report] Clean error report',
  cleanReport: '[Report] Clean report',
  setFiles: '[Files] Set files',
  setFolder: '[Files] Set folder',
  setRemoveFiles: '[Files] Set remove files',
  setRemoveFolder: '[Files] Set remove folder',
  cleanFolder: '[Files] Clean folder',

  setReportActive: '[Cloud] Set report active',
  setPathActive: '[Cloud] Set path active',
  removeReportActive: '[Cloud] Remove report active',
  removePathActive: '[Cloud] Remove path active',
};

export const typesError = {
  setDeviceError: '[Error Device] setError',
  clearDeviceError: '[Error Device] clearError',
  setServerError: '[Error Server] setError',
  clearServerError: '[Error Server] clearError',
};

export const typeErrorLocation = {
  device: 'device',
  server: 'server',
};

export const typesSearchSection = {
  global: 'global',
  patients: 'patients',
  reports: 'reports',
};

export const typesSearch = {
  setTermSearch: '[Search] Set term search',
  setDataSearch: '[Search] Set data search',
  cleanDataSearch: '[Search] Clean data search',
  cleanTermSearch: '[Search] Clean term search',
  cleanAllSearch: '[Search] Clean all search',
  setSectionSearch: '[Search] Set section search',
  cleanSectionSearch: '[Search] Clean section search',
};
