import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { BoxPage } from '../ui/BoxPage';
import ErrorIcon from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';

export const ErrorBox = ({
  title = 'reusable.general_error',
  loadData,
  othersComponents,
  isPage = true,
}) => {
  const [t] = useTranslation('common');
  const getTitle = () => {
    if (title === 'reusable.general_error' && loadData) {
      return t('reusable.general_error_retry');
    }
    return t(title);
  };

  if (isPage) {
    return (
      <BoxPage
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='90vh'
      >
        <Stack spacing={2} sx={{ p: 2 }}>
          <Box sx={{ textAlign: 'center' }}>
            <ErrorIcon color='error' sx={{ fontSize: 100 }} />
          </Box>
          <Typography variant='h5' sx={{ textAlign: 'center' }}>
            {getTitle()}
          </Typography>
          {Boolean(loadData) && (
            <Box display='flex' justifyContent='center'>
              <Button variant='contained' onClick={loadData}>
                {t('reusable.retry')}
              </Button>
            </Box>
          )}
          {othersComponents && othersComponents}
        </Stack>
      </BoxPage>
    );
  }

  return (
    <>
      <Stack spacing={2} sx={{ p: 2 }}>
        <Box sx={{ textAlign: 'center' }}>
          <ErrorIcon color='error' sx={{ fontSize: 100 }} />
        </Box>
        <Typography variant='body1' sx={{ textAlign: 'center' }}>
          {getTitle()}
        </Typography>
        {Boolean(loadData) && (
          <Box display='flex' justifyContent='center'>
            <Button variant='contained' onClick={loadData} size='small'>
              {t('reusable.retry')}
            </Button>
          </Box>
        )}
        {othersComponents && othersComponents}
      </Stack>
    </>
  );
};
