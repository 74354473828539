import { Alert, AlertTitle } from '@mui/material';
import { dateCountry } from '../helpers/dateCountry';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Grow from '@mui/material/Grow';
import moment from 'moment/moment';
import { Box } from '@mui/system';

export const AlertMaintenance = ({ fixed }) => {
  const [t, i18n] = useTranslation('common');
  const [openAlert, setOpenAlert] = useState(false);
  const { title, description, startTime } = useSelector(
    (state) => state.maintenance,
  );
  const isWillBeMaintenance = moment(startTime).diff(moment(), 'days');
  const currentTitle =
    title[i18n.language] ||
    t('maintenance.alert.title', {
      date: dateCountry(i18n.language, startTime),
    });

  const currentDescription =
    description[i18n.language] || t('maintenance.alert.message');

  useEffect(() => {
    if (isWillBeMaintenance >= 0 && isWillBeMaintenance <= 7) {
      setOpenAlert(true);
    }
  }, [startTime]);

  if (isWillBeMaintenance < 0) return <></>;

  if (fixed) {
    return (
      <Box
        sx={{
          position: 'fixed',
          width: '100%',
          p: 'env(safe-area-inset-top) 0 0 env(safe-area-inset-left)',
        }}
      >
        <Grow
          in={openAlert}
          style={{ transformOrigin: '0 0 0' }}
          {...(openAlert ? { timeout: 1000 } : {})}
        >
          <Alert severity='info' onClose={() => setOpenAlert(false)}>
            <AlertTitle>{currentTitle}</AlertTitle>
            {currentDescription}
          </Alert>
        </Grow>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        p: openAlert
          ? 'env(safe-area-inset-top) 0 0 env(safe-area-inset-left)'
          : 0,
      }}
    >
      <Grow
        in={openAlert}
        style={{
          transformOrigin: '0 0 0',
          display: openAlert ? 'flex' : 'none',
        }}
        {...(openAlert ? { timeout: 1000 } : {})}
      >
        <Alert severity='info' onClose={() => setOpenAlert(false)}>
          <AlertTitle>{currentTitle}</AlertTitle>
          {currentDescription}
        </Alert>
      </Grow>
    </Box>
  );
};
