import loadable from '@loadable/component';
import { Route, Routes } from 'react-router-dom';

const TemplatesPage = loadable(
  () => import('../../pages/Templates/TemplatesPage'),
  {
    resolveComponent: (components) => components.TemplatesPage,
  },
);

const TemplateCreateUpdatePage = loadable(
  () => import('../../pages/TemplateCreateUpdate/TemplateCreateUpdatePage'),
  {
    resolveComponent: (components) => components.TemplateCreateUpdatePage,
  },
);

const TemplateViewPage = loadable(
  () => import('../../pages/TemplateView/TemplateViewPage'),
  {
    resolveComponent: (components) => components.TemplateViewPage,
  },
);

const TemplatesUploadPage = loadable(
  () => import('../../pages/TemplatesUpload/TemplatesUploadPage'),
  {
    resolveComponent: (components) => components.TemplatesUploadPage,
  },
);

export const TemplatesRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<TemplatesPage />} />
      <Route path='create' element={<TemplateCreateUpdatePage />} />
      <Route path='edit/:id' element={<TemplateCreateUpdatePage />} />
      <Route path='view/:id' element={<TemplateViewPage />} />
      <Route path='upload' element={<TemplatesUploadPage />} />
    </Routes>
  );
};
