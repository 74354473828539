export const environment = {
  apiUrl: import.meta.env.VITE_API_URL,
  apiSocketUrl: import.meta.env.VITE_API_SOCKET,
  apiKeyEditor: import.meta.env.VITE_EDITOR_API_KEY,
  apiKeyStripe: import.meta.env.VITE_STRIPE_API_KEY,
  apiCloud: import.meta.env.VITE_API_CLOUD,
  apiPay: import.meta.env.VITE_API_PAY,
  lookupkey: import.meta.env.VITE_LOOKUP_KEY,
  siteUlr: import.meta.env.VITE_SITE_URL,
  appVersion: __APP_VERSION__,
};
