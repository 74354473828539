export const commentsSocket = {
  newComment: 'new-comment',
  newAnswer: 'new-answer',
  updateComment: 'update-comment',
  updateAnswer: 'update-answer',
  deleteComment: 'delete-comment',
  deleteAnswer: 'delete-answer',
};

export const reportsSocket = {
  newReport: 'new-interpretation',
  deleteReport: 'delete-interpretation',
  updateCloudFolderImages: 'update-cloud-folder-images',
  availablePatient: 'available-patient',
};

export const cloudSocket = {
  newFile: 'new-cloud-file',
  deleteFile: 'delete-cloud-file',
  newFolder: 'new-cloud-folder',
  deleteFolder: 'delete-cloud-folder',
  updateFolder: 'update-cloud-folder',
};

export const wwWlSocket = {
  createWwwl: 'create-wwwl',
  updateWwwl: 'update-wwwl',
  deleteWwwl: 'delete-wwwl',
};

export const notificationsSocket = {
  newNotification: 'new-notification',
  deleteNotification: 'delete-notification',
};

export const userSocket = {
  deleteFriend: 'delete-friend',
  friendshipRequest: 'friendship-request',
  friendshipRequestCancel: 'friendship-request-cancel',
  friendshipRequestConfirm: 'friendship-request-confirm',
  updateOnline: 'update-online',
  updateSpaceUsed: 'update-space-used',
};

export const ChatsSocket = {
  newMessage: 'new-message',
  deleteChat: 'delete-chat',
  newChat: 'new-chat',
  viewChat: 'view-chat',
};

export const BlogSocket = {
  updateLike: 'update-like',
  updateLikeFriend: 'update-like-friend',
  deleteArticle: 'delete-article',
  newComment: 'new-comment-blog',
  newCommentFriend: 'new-comment-blog-friend',
  updateComment: 'update-comment-blog',
  updateCommentFriend: 'update-comment-blog-friend',
  deleteComment: 'delete-comment-blog',
  deleteCommentFriend: 'delete-comment-blog-friend',
};

export const AuthSocket = {
  closeAllSessions: 'closeAllSessions',
};
