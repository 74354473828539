import React, { useEffect } from 'react';
import { Container, Paper, Stack } from '@mui/material';
import { Box } from '@mui/system';
import queryString from 'query-string';
import { LoginForm } from './components/LoginForm';
import { LogoContainer } from './components/LogoContainer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { startCheckingAuth } from '../../actions/auth';

export const LoginPage = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.auth.userData);
  const { token = '', forgotPassword } = queryString.parse(search);
  const handleToken = () => {
    if (!token) return;
    localStorage.setItem('token', token);
    dispatch(startCheckingAuth());
  };

  useEffect(() => {
    if (status === 'register') {
      navigate('/auth/register/code');
    }
  }, [status]);

  useEffect(() => {
    handleToken();
  }, [token]);

  return (
    <Container
      className='animate__animated animate__fadeIn animate__faster'
      sx={{ bgcolor: 'primary.dark', width: '100%' }}
      maxWidth='1'
    >
      <Box
        sx={{ m: 'auto', width: '100%' }}
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100vh'
      >
        <Paper
          sx={{
            width: {
              xs: '100%',
              sm: '80%',
              md: '60%',
              lg: '70%',
              xl: '50%',
            },
            height: '100%',
          }}
        >
          <Stack direction={{ xs: 'column', sm: 'row' }}>
            <LogoContainer />
            <LoginForm forgotPassword={Number(forgotPassword)} />
          </Stack>
        </Paper>
      </Box>
    </Container>
  );
};
