import { useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { alpha, styled } from '@mui/system';
import InputBase from '@mui/material/InputBase';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
export const SearchInput = () => {
  const [t] = useTranslation('common');
  const navigate = useNavigate();
  const goSearch = (values, { resetForm }) => {
    const { search } = values;
    navigate(`/search?term=${encodeURIComponent(search)}`);
    resetForm();
  };

  const goSearchButton = () => navigate('/search');
  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: { search: '' },
    onSubmit: goSearch,
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (values.search)
        navigate(`/search?term=${encodeURIComponent(values.search)}`);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [values.search]);

  return (
    <Box display='flex' justifyContent='center' alignContent='center'>
      <form onSubmit={handleSubmit}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder={t('reusable.search') + '...'}
            inputProps={{ 'aria-label': 'search' }}
            type='search'
            name='search'
            value={values.search}
            onChange={handleChange}
          />
        </Search>
      </form>
      <IconButton
        sx={{
          color: 'white',
          display: {
            sm: 'none',
            md: 'none',
          },
        }}
        onClick={goSearchButton}
      >
        <SearchIcon />
      </IconButton>
    </Box>
  );
};
