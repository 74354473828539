import {
  AppBar,
  Badge,
  IconButton,
  SwipeableDrawer,
  Toolbar,
  Typography,
} from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useContext, useEffect, useState } from 'react';
import { useHttp } from '../hooks/useHttp';
import { getNotifications } from '../services/NotificationsService';
import { NotificationsList } from './NotificationsList';
import CloseIcon from '@mui/icons-material/Close';
import { DrawerHeader } from '../ui/MenuDrawer/MenuDrawer';
import { SocketContext } from '../context/SocketContext';
import { notificationsSocket } from '../types/sockets';

import soundNotification from '../assets/mixkit-positive-notification-951.mp3';
import { LoadingBox } from './LoadingBox';
import { ErrorBox } from './ErrorBox';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';

const { newNotification, deleteNotification } = notificationsSocket;

export const NotificationsPanel = () => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [t] = useTranslation('common');
  const { socket } = useContext(SocketContext);
  const [notifications, setNotifications] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const { request, loading, error } = useHttp(getNotifications, { page });

  const numberPendingNotifications = notifications.filter(
    (n) => !n.status,
  ).length;

  const hanldeGetNotifications = async () => {
    const result = await request();
    if (!result) return;
    setNotifications(result.data.notifications);
    setTotalPages(result.data.totalPages);
  };

  const handleMoredData = () => setPage(page + 1);
  const handleDrawerToggle = () => setOpen(!open);

  const handleUpdateStatus = (id, status = false) => {
    const newNotifications = notifications.map((notification) => {
      if (notification._id === id) notification.status = status;
      return notification;
    });
    setNotifications(newNotifications);
  };

  const handleAddNotification = (notification) => {
    setNotifications((prev) => [notification, ...prev]);
    const audio = new Audio(soundNotification);
    audio.play();
  };

  const handleRemoveNotification = ({ id }) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification._id !== id),
    );
  };

  useEffect(() => {
    hanldeGetNotifications();
  }, [page]);

  useEffect(() => {
    socket.on(newNotification, handleAddNotification);
    socket.on(deleteNotification, handleRemoveNotification);

    return () => {
      socket.off(newNotification);
      socket.off(deleteNotification);
    };
  }, [socket]);

  return (
    <>
      <IconButton sx={{ color: 'white' }} onClick={handleDrawerToggle}>
        <Badge badgeContent={numberPendingNotifications} color='error'>
          <NotificationsNoneIcon />
        </Badge>
      </IconButton>
      <SwipeableDrawer
        anchor='right'
        open={open}
        onClose={handleDrawerToggle}
        onOpen={handleDrawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: {
              xs: '100%',
              sm: '100%',
              md: '30%',
              lg: '30%',
              xl: '30%',
            },
            boxSizing: 'border-box',
          },
        }}
      >
        <DrawerHeader
          sx={{
            display: {
              xs: 'none',
              md: 'flex',
            },
          }}
        />
        <AppBar
          sx={{
            pt: 'env(safe-area-inset-top) ',
            position: 'relative',
            display: {
              xs: 'flex',
              md: 'none',
            },
          }}
        >
          <Toolbar
            sx={{
              justifyContent: 'space-between',
            }}
          >
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              {t('sidebar.notifications')}
            </Typography>
            <IconButton
              edge='start'
              onClick={handleDrawerToggle}
              color='error'
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {loading && (
          <Box
            sx={{
              width: '100%',
            }}
          >
            <LoadingBox />
          </Box>
        )}
        {error && <ErrorBox isPage={false} loadData={hanldeGetNotifications} />}
        {!loading && !error && (
          <NotificationsList
            notifications={notifications}
            page={page}
            totalPages={totalPages}
            moreData={handleMoredData}
            handleDrawerToggle={handleDrawerToggle}
            handleUpdateStatus={handleUpdateStatus}
            handleClear={() => setNotifications([])}
          />
        )}
      </SwipeableDrawer>
    </>
  );
};
