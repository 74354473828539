import loadable from '@loadable/component';
import { CssBaseline } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
const ReportPatientPage = loadable(
  () => import('../pages/ReportPatient/ReportPatientPage'),
  {
    resolveComponent: (components) => components.ReportPatientPage,
  },
);

export const ReportPublicRoutes = () => {
  return (
    <>
      <CssBaseline />
      <Routes>
        <Route path='/report' element={<ReportPatientPage />} />
      </Routes>
    </>
  );
};
