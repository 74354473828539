import React, { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PropTypes from 'prop-types';
import {
	Divider,
	IconButton,
	LinearProgress,
	Toolbar,
	Typography,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isBrowser } from 'react-device-detect';
import { SearchInput } from './SearchInput';
import { NameRoute } from '../../helpers/NameRoute';
import { HomePageHelper } from '../../helpers/HomePageHelper';
import { NotificationsPanel } from '../../components/NotificationsPanel';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/system';
import { setOpenViewDrawer } from '../../features/files/filesSlice';
import { setOpen } from '../../features/DownloadsFiles/DownloadsFilesSlice';
import queryString from 'query-string';

const drawerWidth = 300;
export const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => {
		return prop !== 'open';
	},
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	[theme.breakpoints.down('sm')]: {
		zIndex: theme.zIndex.drawer,
	},
}));

const MenuAppBar = ({ mobileOpen, setMobileOpen, handleDrawerOpen, open }) => {
	const [t] = useTranslation('common');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { files, open: openFileUpload } = useSelector((state) => state.files);
	const { files: filesDownload, open: openDownload } = useSelector(
		(state) => state.downloadsFiles,
	);
	const [isHomePage, setIsHomePage] = useState(false);
	const [nameLocation, setNameLocation] = useState('sidebar.interpretations');
	const { pathname, search } = useLocation();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const handleBack = () => {
		const { prevUrl } = queryString.parse(search);
		if (pathname.includes('cloud') && isBrowser) {
			const sectionsPath = pathname.split('/');
			const id = sectionsPath[sectionsPath.length - 1];
			navigate(`/report/view/${id}`, { replace: true });
			return;
		}

		if (pathname.includes('report/view') && !prevUrl) {
			navigate('/', { replace: true });
			return;
		}

		// if (pathname.includes('report/view') && prevUrl) {
		// 	navigate(prevUrl, { replace: true });
		// 	return;
		// }

		navigate(-1);
	};

	useEffect(() => {
		setNameLocation(NameRoute(pathname));
		setIsHomePage(HomePageHelper(pathname));
	}, [pathname]);

	return (
		<>
			<AppBar
				position='fixed'
				open={open}
				sx={{
					p: 'env(safe-area-inset-top) 0 0 env(safe-area-inset-left)',
					bgcolor: 'primary.dark',
				}}
			>
				<Toolbar>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						onClick={handleDrawerOpen}
						edge='start'
						sx={{
							marginRight: 5,
							display: { xs: 'none', sm: 'block', md: 'block' },
							...(open && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						edge='start'
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
					{!isHomePage && (
						<IconButton
							color='inherit'
							aria-label='back route'
							edge='start'
							onClick={handleBack}
						>
							<ArrowBackIosIcon />
						</IconButton>
					)}
					<Typography
						variant='h6'
						noWrap
						component='div'
						className='text-capitalize-first-letter'
						sx={{
							flexGrow: 1,

							textAlign: {
								xs: 'center',
								sm: 'center',
								md: 'start',
							},
						}}
					>
						{t(nameLocation)}
					</Typography>

					{!pathname.includes('/search') && (
						<>
							<SearchInput />
							<Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
						</>
					)}

					<NotificationsPanel />
					<Box
						sx={{
							display: Boolean(files.length > 0 || filesDownload.length > 0)
								? 'flex'
								: 'none',
							gap: 2,
						}}
					>
						{files.length > 0 && (
							<Box>
								<IconButton
									onClick={() => dispatch(setOpenViewDrawer(!openFileUpload))}
								>
									<CloudUploadIcon />
								</IconButton>
								<LinearProgress />
							</Box>
						)}
						{filesDownload.length > 0 && (
							<Box>
								<IconButton onClick={() => dispatch(setOpen(!openDownload))}>
									<CloudDownloadIcon />
								</IconButton>
								<LinearProgress />
							</Box>
						)}
					</Box>
				</Toolbar>
			</AppBar>
		</>
	);
};

MenuAppBar.propTypes = {
	mobileOpen: PropTypes.bool.isRequired,
	setMobileOpen: PropTypes.func.isRequired,
	drawerWidth: PropTypes.number.isRequired,
};
export default MenuAppBar;
