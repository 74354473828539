export const NameRoute = (path) => {
  if (path === '/' || path.includes('/report/all')) {
    return 'sidebar.interpretations';
  }
  if (path.includes('/report/view')) {
    return 'interpretation.title';
  }

  if (path.includes('/report/edit')) {
    return 'interpretation.edit.title';
  }

  if (path.includes('/report/edit/content')) {
    return 'interpretation.view.interpreted';
  }

  if (path.includes('/report/create')) {
    return 'interpretation.create.title';
  }

  if (path.includes('/report/cloud')) {
    return 'interpretation.view.menu.cloud';
  }

  if (path.includes('/patient/all')) {
    return 'sidebar.patients';
  }

  if (path.includes('/patient/detail') && !path.includes('graphics')) {
    return 'patients.view.title';
  }

  if (path.includes('/patient/detail/graphics')) {
    return 'patients.graphics.title';
  }

  if (path.includes('/patient/edit')) {
    return 'patients.edit.title';
  }

  if (path.includes('/patient/create')) {
    return 'patients.create.title';
  }
  if (
    path.includes('/search') &&
    !path.includes('/search/patients') &&
    !path.includes('/search/reports')
  ) {
    return 'search_page.title';
  }

  if (path.includes('/search/patients')) {
    return 'search_page.results_patients';
  }

  if (path.includes('/search/reports')) {
    return 'search_page.results_reports';
  }

  if (path.includes('/patient/reports/')) {
    return 'patients.reports';
  }

  if (path.includes('/exports')) {
    return 'sidebar.exports';
  }

  if (
    path.includes('/settings') &&
    !path.includes('/settings/account') &&
    !path.includes('/settings/labels') &&
    !path.includes('/settings/subscription')
  ) {
    return 'settings.title';
  }

  if (path.includes('/settings/subscription')) {
    return 'settings.headers.subscription';
  }

  if (path.includes('/settings/labels')) {
    return 'settings.labels_section.header';
  }
  if (path.includes('/settings/account')) {
    return 'settings.title_sections.account';
  }

  if (path.includes('dicomviewer')) {
    return 'Dicom viewer';
  }

  if (path.includes('profile')) {
    return 'sidebar.perfil.myperfil';
  }

  if (path.includes('/collaborators/create')) {
    return 'collaborators.headers.create';
  }

  if (path.includes('/collaborators/edit')) {
    return 'collaborators.headers.update';
  }

  if (path.includes('collaborators')) {
    return 'sidebar.collaborators';
  }

  if (path.includes('friends/reports/editor')) {
    return 'sidebar.my_edits';
  }

  if (path.includes('friends/reports')) {
    return 'sidebar.interpretations_friends';
  }

  if (path.includes('friends')) {
    return 'friends.title';
  }

  if (path.includes('messages')) {
    return 'sidebar.messages';
  }

  if (path.includes('/blog/dashboard/edit')) {
    return 'blog.edit.title';
  }

  if (path.includes('/blog/dashboard')) {
    return 'blog.my_posts';
  }

  if (path.includes('blog')) {
    return 'sidebar.blog';
  }

  if (path.includes('news')) {
    return 'sidebar.news';
  }

  if (path.includes('feedback')) {
    return 'updates.feedback';
  }

  if (path.includes('templates/create')) {
    return 'templates.create';
  }

  if (path.includes('templates/edit')) {
    return 'templates.update';
  }

  if (path.includes('templates/upload')) {
    return 'templates.uploads.title';
  }

  if (path.includes('templates')) {
    return 'sidebar.templates';
  }

  if (path.includes('help')) {
    return 'sidebar.help';
  }
};
