import { useTheme } from '@emotion/react';
import {
  Backdrop,
  CircularProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const LoadingListInfinite = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [t] = useTranslation('common');

  if (matches)
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          p: 3,
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Backdrop
      sx={{
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={true}
    >
      <Box>
        <CircularProgress color='primary' size={50} />
      </Box>
      <Typography variant='body1'>{t('reusable.loading')}</Typography>
    </Backdrop>
  );
};
