import { useTheme } from '@emotion/react';
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

export const DialogResponsive = ({
  children,
  open,
  onClose,
  viewCloseButton = false,
  alwaysFullScreen = false,
  title = '',
}) => {
  const theme = useTheme();
  const fullScreen = !alwaysFullScreen
    ? useMediaQuery(theme.breakpoints.down('md'))
    : alwaysFullScreen;
  const handleClose = (_, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  useEffect(() => {
    if (!open) onClose();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      fullScreen={fullScreen}
      disableEscapeKeyDown
      keepMounted
      TransitionComponent={Transition}
      scroll='paper'
      sx={{ p: 'env(safe-area-inset-top) 0 0 env(safe-area-inset-left)' }}
      aria-labelledby='responsive-dialog-title'
    >
      {viewCloseButton && (
        <AppBar sx={{ position: 'relative', width: '100%' }}>
          <Toolbar
            sx={{
              justifyContent: title ? 'space-between' : 'flex-end',
              bgcolor: 'primary.dark',
            }}
          >
            {title && (
              <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                {title}
              </Typography>
            )}
            <IconButton
              edge='start'
              onClick={handleClose}
              color='error'
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      {children}
    </Dialog>
  );
};

DialogResponsive.prototype = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
