import React, { createContext } from 'react';
import { environment } from '../environment/environment';
import { useSocket } from '../hooks/useHook';
const url = environment.apiSocketUrl;

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const { socket, online } = useSocket(url);
  return (
    <SocketContext.Provider value={{ socket, online }}>
      {children}
    </SocketContext.Provider>
  );
};
