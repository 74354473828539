import {
  AppBar,
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  List,
  ListSubheader,
  Paper,
  Toolbar,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenViewDrawer } from '../features/files/filesSlice';
import { UploadFileItem } from './UploadFileItem';
import { UploadFolderNameItem } from './UploadFolderNameItem';
import { useTheme } from '@emotion/react';
import InfiniteScroll from 'react-infinite-scroll-component';

export const UploadFiles = () => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const theme = useTheme();
  const { files, open: openFileUpload } = useSelector((state) => state.files);
  const [uploadFiles, setUploadFiles] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (files.length > 0 && !uploadFiles) {
      setUploadFiles(true);
      setOpen(true);
    }

    if (files.length === 0) {
      setUploadFiles(false);
      setOpen(false);
    }
  }, [files]);

  useEffect(() => {
    dispatch(setOpenViewDrawer(open));
  }, [open]);

  useEffect(() => {
    setOpen(openFileUpload);
  }, [openFileUpload]);

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <AppBar
        position='relative'
        sx={{
          bgcolor: 'primary.dark',
          p: 'env(safe-area-inset-top) 0 0 env(safe-area-inset-left)',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            {t('files.uploads')}
          </Typography>
          <IconButton color='error' onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          width: {
            xs: '100vw',
            sm: '100vw',
            md: '40vw',
            lg: '30vw',
            xl: '20vw',
          },
        }}
      >
        <Paper>
          <List>
            <ListSubheader
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {t('cloud.labels.remaining_files', { value: files.length })}
              {files.length > 0 && <CircularProgress size={20} />}
            </ListSubheader>
            <InfiniteScroll
              dataLength={files.length}
              next={() => {}}
              hasMore={false}
              loader={<h4>Loading...</h4>}
            >
              {files.map(
                ({ id, type, reportId, path, folder = '', file, isCompress }) =>
                  type === 'file' ? (
                    <UploadFileItem
                      key={id}
                      id={id}
                      file={file}
                      path={path}
                      reportId={reportId}
                      folder={folder}
                      isCompress={isCompress}
                    />
                  ) : (
                    <UploadFolderNameItem
                      key={id}
                      folderName={folder}
                      id={id}
                    />
                  ),
              )}
            </InfiniteScroll>
          </List>
        </Paper>
      </Box>
    </Drawer>
  );
};
