import React from 'react';
import ReactDOM from 'react-dom/client';
import { TeleimagenologiaApp } from './TeleimagenologiaApp';

import 'animate.css';
import './index.scss';

ReactDOM.createRoot(document.getElementById('root')).render(
  <TeleimagenologiaApp />,
);
