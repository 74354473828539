import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import { AlertReOpen } from '../../../components/AlertReOpen';
import { LoadingButton } from '@mui/lab';
import { resetPassword } from '../services/resetService';
import { useHttp } from '../../../hooks/useHttp';
import { useEffect, useState } from 'react';
import { DialogResponsive } from '../../../ui/DialogResponsive';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
});

const initialValuesAlert = {
  open: false,
  severity: 'error',
  message: '',
};

export const DialogResetPassword = ({ open, handleClose }) => {
  const [t] = useTranslation('common');
  const [dataAlert, setDataAlert] = useState(initialValuesAlert);

  const { open: openAlert, severity, message } = dataAlert;

  const { values, handleSubmit, handleChange, touched, errors } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (_, { resetForm }) => {
      handleSendEmail().then(() => {
        setTimeout(() => {
          setDataAlert(initialValuesAlert);
          resetForm();
          handleClose();
        }, 3000);
      });
    },
  });

  const { request, loading, error } = useHttp(resetPassword, {
    email: values.email,
  });

  const handleSendEmail = async () => {
    setDataAlert(initialValuesAlert);
    const result = await request();
    if (!result) return;
    setDataAlert({
      open: true,
      severity: 'success',
      message: t('reset_password.modal_success.message'),
    });
  };

  useEffect(() => {
    if (Boolean(error)) {
      setDataAlert({
        open: true,
        severity: 'error',
        message: t('reset_password.modal_error.message'),
      });
    }
  }, [error]);

  return (
    <DialogResponsive open={open} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',

          alignContent: 'flex-end',
        }}
      >
        <DialogTitle
          className='text-capitalize-first-letter'
          sx={{ textAlign: 'center', flexGrow: 1 }}
        >
          {t('login_page.password_lost')}
        </DialogTitle>
        <Box>
          <IconButton color='error' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <form onSubmit={handleSubmit}>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: {
              xs: '70vh',
              md: 'auto',
            },
            gap: 2,
          }}
        >
          <DialogContentText>
            {t('login_page.password_lost_content')}
          </DialogContentText>
          <TextField
            size='small'
            type='email'
            placeholder={t('register_page.email')}
            name='email'
            value={values.email}
            onChange={handleChange}
            error={touched.email && Boolean(errors.email)}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <AlertReOpen
            open={openAlert}
            message={message}
            severity={severity}
            onClose={() => {
              setDataAlert({
                ...dataAlert,
                open: false,
              });
            }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <LoadingButton variant='contained' type='submit' loading={loading}>
              {t('reusable.send')}
            </LoadingButton>
          </Box>
        </DialogContent>
      </form>
    </DialogResponsive>
  );
};
