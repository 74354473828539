import { Alert, Backdrop, Snackbar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Detector } from 'react-detect-offline';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import loadable from '@loadable/component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import { SocketContext } from '../context/SocketContext';
import { UploadFiles } from '../components/UploadFiles';
import { AuthSocket, wwWlSocket } from '../types/sockets';
import {
  addWwWl,
  removeWwWl,
  startLogout,
  updateWwWlItem,
} from '../actions/auth';
import { MenuDrawer } from '../ui/MenuDrawer/MenuDrawer';
import { ReportRoutes } from './components/ReportRoutes';
import { CollaboratorsRoutes } from './components/CollaboratorsRoutes';
import { FriendsRoutes } from './components/FriendsRoutes';
import { useHttp } from '../hooks/useHttp';
import { getSpace } from '../ui/services/UserService';
import {
  removeData,
  setDataSpace,
} from '../features/cloudSpace/cloudSpaceSlice';
import { AlertMaintenance } from '../components/AlertMaintenance';
import { logoutUser } from '../services/auth';
import { useCookies } from 'react-cookie';
import { TemplatesRoutes } from './components/TemplatesRoutes';
import { environment } from '../environment/environment';

const PatientsRoutes = loadable(() => import('./components/PatientsRoutes'), {
  resolveComponent: (components) => components.PatientsRoutes,
});
const SearchRoute = loadable(() => import('./components/SearchRoute'), {
  resolveComponent: (components) => components.SearchRoute,
});
const ExportRoutes = loadable(() => import('./components/ExportRoutes'), {
  resolveComponent: (components) => components.ExportRoutes,
});
const SettingsRoutes = loadable(() => import('./components/SettingsRoutes'), {
  resolveComponent: (components) => components.SettingsRoutes,
});
const UserRoutes = loadable(() => import('./components/UserRoutes'), {
  resolveComponent: (components) => components.UserRoutes,
});
const DicomViewer = loadable(
  () => import('../pages/DicomViewer/DicomViewerPage'),
  {
    resolveComponent: (components) => components.DicomViewerPage,
  },
);

const MessagesRoutes = loadable(() => import('./components/MessagesRoutes'), {
  resolveComponent: (components) => components.MessagesRoutes,
});

const BlogRoutes = loadable(() => import('./components/BlogRoutes'), {
  resolveComponent: (components) => components.BlogRoutes,
});

const NewsRoutes = loadable(() => import('./components/NewsRoutes'), {
  resolveComponent: (components) => components.NewsRoutes,
});

const FeedbakRoutes = loadable(() => import('./components/FeedbackRoutes'), {
  resolveComponent: (components) => components.FeedbackRoutes,
});

const HelpPage = loadable(() => import('../pages/Help/HelpPage'), {
  resolveComponent: (components) => components.HelpPage,
});

export const DashboardRoutes = () => {
  const { token, userData } = useSelector((state) => state.auth);
  const [cookies, setCookie, removeCookie] = useCookies();
  const { online, socket } = useContext(SocketContext);
  const dispatch = useDispatch();
  const [statusConnectionDevice, setStatusConnectionDevice] = useState(true);
  const [t] = useTranslation('common');
  const { pathname, search } = useLocation();
  const { request } = useHttp(getSpace);
  const { request: requestLogout } = useHttp(logoutUser);
  localStorage.setItem('lastPath', `${pathname}${search}`);

  const handleNewWwWl = ({ wwWl }) => dispatch(addWwWl(wwWl));
  const handleUpdatewWwWl = ({ wwWl }) => dispatch(updateWwWlItem(wwWl));
  const handleDeletewWwWl = ({ id }) => dispatch(removeWwWl(id));
  const handleCloseSession = () => {
    requestLogout();
    dispatch(startLogout());
    dispatch(removeData());
    removeCookie('token', { path: '/' });
  };

  const hanldeGetSpace = async () => {
    const result = await request();
    if (!result.ok) return;
    dispatch(setDataSpace(result.data));
  };

  useEffect(() => {
    socket.emit('settings-user', { jwt: token });
    if (token) hanldeGetSpace();
  }, [token]);

  useEffect(() => {
    socket.emit('settings-user', { jwt: token });
  }, [online]);

  useEffect(() => {
    socket.on(wwWlSocket.createWwwl, handleNewWwWl);
    socket.on(wwWlSocket.updateWwwl, handleUpdatewWwWl);
    socket.on(wwWlSocket.deleteWwwl, handleDeletewWwWl);
    socket.on(AuthSocket.closeAllSessions, handleCloseSession);
    return () => {
      socket.off(wwWlSocket.createWwwl);
      socket.off(wwWlSocket.updateWwwl);
      socket.off(wwWlSocket.deleteWwwl);
      socket.off(AuthSocket.closeAllSessions);
    };
  }, [socket]);

  return (
    <MenuDrawer>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!statusConnectionDevice}
      >
        <Detector
          polling={{ url: environment.apiUrl, timeout: 3000 }}
          onChange={(isOnline) => setStatusConnectionDevice(isOnline)}
          render={({ online }) => (
            <>
              <Snackbar
                open={!online}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{
                  m: 'env(safe-area-inset-top) 0 0 env(safe-area-inset-left)',
                }}
              >
                <Alert
                  icon={<SignalWifiOffIcon fontSize='inherit' />}
                  severity='error'
                  sx={{ width: '100%' }}
                >
                  {t('reusable.no_connection_text')}
                </Alert>
              </Snackbar>
            </>
          )}
        />
        {/* <Snackbar
          open={!online && statusConnectionDevice}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          sx={{
            m: 'env(safe-area-inset-top) 0 0 env(safe-area-inset-left)',
          }}
        >
          <Alert severity='error' sx={{ width: '100%' }}>
            {t('interpretation.home.socket_error')}
          </Alert>
        </Snackbar> */}
      </Backdrop>

      <UploadFiles />
      <AlertMaintenance />
      <Routes>
        <Route path='report/*' element={<ReportRoutes />} />
        <Route path='dicomviewer/:id' element={<DicomViewer />} />
        <Route path='patient/*' element={<PatientsRoutes />} />
        <Route path='search/*' element={<SearchRoute />} />
        <Route path='exports/*' element={<ExportRoutes />} />
        <Route path='settings/*' element={<SettingsRoutes />} />
        <Route path='user/*' element={<UserRoutes />} />
        <Route path='collaborators/*' element={<CollaboratorsRoutes />} />
        <Route path='friends/*' element={<FriendsRoutes />} />
        <Route path='messages/*' element={<MessagesRoutes />} />
        <Route path='blog/*' element={<BlogRoutes />} />
        <Route path='news/*' element={<NewsRoutes />} />
        <Route path='feedback/*' element={<FeedbakRoutes />} />
        <Route path='help' element={<HelpPage />} />
        <Route path='templates/*' element={<TemplatesRoutes />} />
        <Route path='*' element={<Navigate to='report' />} />
      </Routes>
    </MenuDrawer>
  );
};
