import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import DoorBackIcon from '@mui/icons-material/DoorBack';
import LogoutIcon from '@mui/icons-material/Logout';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { startLogout } from '../../actions/auth';
import { removeData } from '../../features/cloudSpace/cloudSpaceSlice';
import { useHttp } from '../../hooks/useHttp';
import { useCookies } from 'react-cookie';
import { logoutUser } from '../../services/auth';
import { resetFiles } from '../../features/DownloadsFiles/DownloadsFilesSlice';
import { resetFilesUpload } from '../../features/files/filesSlice';

export const ListLogout = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [t] = useTranslation('common');
  const [openLogout, setLogout] = useState(false);
  const { request } = useHttp(logoutUser);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setLogout(true);
  };

  const handleClose = () => {
    setLogout(false);
  };

  const logout = () => {
    setLogout(false);
    request();
    dispatch(startLogout());
    dispatch(removeData());
    dispatch(resetFiles());
    dispatch(resetFilesUpload());
    removeCookie('token', { path: '/' });
  };

  return (
    <>
      <List
        sx={{
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        <ListItemButton onClick={handleClickOpen}>
          <ListItemIcon>
            <LogoutIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary={t('sidebar.perfil.sign')} />
        </ListItemButton>
      </List>
      <Dialog
        open={openLogout}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Typography align='center' sx={{ p: 1 }}>
          <DoorBackIcon sx={{ fontSize: 70 }} />
        </Typography>
        <DialogTitle
          className='text-capitalize-first-letter'
          id='alert-dialog-title'
        >
          {t('sidebar.logout')}
        </DialogTitle>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button onClick={logout} variant='contained'>
            {t('reusable.yes')}
          </Button>
          <Button
            onClick={handleClose}
            autoFocus
            variant='contained'
            color='error'
          >
            {t('reusable.no')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
