import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Collapse,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UserSpace } from './UserSpace';
import { SocketContext } from '../../context/SocketContext';
import { userSocket } from '../../types/sockets';
import StyledBadge from '../../pages/Messages/components/StyledBadge';
import { useDispatch } from 'react-redux';
import { updateOnlineStatus } from '../../features/auth/authSlice';

export const UserItem = ({
  photo,
  name,
  lastName,
  online,
  username,
  handleDrawerOpen,
  mobileOpen,
  openDesktop = false,
  handleChangeStatusMobile,
  handleDrawerClose,
}) => {
  const { socket } = useContext(SocketContext);
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    if (!mobileOpen) handleDrawerOpen();
    setOpen(!open);
  };

  useEffect(() => {
    if (!openDesktop && open) setOpen(false);
  }, [openDesktop]);

  const handleGoSettings = () => {
    setOpen(false);
    if (mobileOpen) handleChangeStatusMobile(false);
    if (openDesktop) handleDrawerClose();
    navigate('/settings');
  };

  const handleGoProfile = () => {
    setOpen(false);
    if (mobileOpen) handleChangeStatusMobile(false);
    if (openDesktop) handleDrawerClose();
    navigate('/user/profile');
  };

  useEffect(() => {
    socket.on(userSocket.updateOnline, (data) => {
      if (username === data.username) {
        dispatch(updateOnlineStatus(data.online));
      }
    });

    return () => {
      socket.off(userSocket.updateOnline);
    };
  }, [socket]);

  return (
    <List
      disablePadding
      sx={{
        mt: 1,
      }}
    >
      <ListItemButton
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'space-between',
        }}
        onClick={handleClick}
      >
        <ListItemAvatar>
          <StyledBadge
            overlap='circular'
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant='dot'
            invisible={!online}
          >
            <Avatar alt='user photo' src={photo} />
          </StyledBadge>
        </ListItemAvatar>
        <Box>
          <Typography variant='subheading' noWrap>
            {name} {lastName}
          </Typography>
        </Box>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        in={open}
        timeout='auto'
        unmountOnExit
        sx={{ bgcolor: 'primary.main', color: 'white' }}
      >
        <List component='div' disablePadding>
          <ListItemButton onClick={handleGoProfile}>
            <ListItemIcon>
              <PersonIcon fontSize='small' sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary={t('sidebar.perfil.myperfil')} />
          </ListItemButton>
          <ListItemButton onClick={handleGoSettings}>
            <ListItemIcon>
              <SettingsIcon fontSize='small' sx={{ color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary={t('sidebar.perfil.settings')} />
          </ListItemButton>
        </List>
      </Collapse>
      <UserSpace />
    </List>
  );
};
