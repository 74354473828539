import { createSlice } from '@reduxjs/toolkit';

const ininitalState = { files: [], folder: '', open: false, activesId: [] };

export const filesSlice = createSlice({
  name: 'files',
  initialState: ininitalState,
  reducers: {
    setFiles: (state, action) => {
      return {
        ...state,
        files: action.payload,
      };
    },
    setFolder: (state, action) => {
      return {
        ...state,
        folder: action.payload,
      };
    },
    setRemoveFiles: (state) => {
      return {
        ...state,
        files: [],
      };
    },
    setRemoveFolder: (state) => {
      return {
        ...state,
        folder: '',
      };
    },
    setOpenViewDrawer: (state, action) => {
      return {
        ...state,
        open: action.payload,
      };
    },
    cleanFolder: () => {
      return {
        files: [],
      };
    },
    addActiveId: (state, action) => {
      return {
        ...state,
        activesId: [...state.activesId, action.payload],
      };
    },
    removeActiveId: (state, action) => {
      return {
        ...state,
        activesId: state.activesId.filter((id) => id !== action.payload),
      };
    },
    addFilesUpload: (state, action) => {
      return {
        ...state,
        files: [...state.files, ...action.payload],
      };
    },
    resetFilesUpload: () => ininitalState,
  },
});

export const {
  setFiles,
  setFolder,
  setRemoveFiles,
  setRemoveFolder,
  cleanFolder,
  setOpenViewDrawer,
  addFilesUpload,
  resetFilesUpload,
  addActiveId,
  removeActiveId,
} = filesSlice.actions;
export default filesSlice.reducer;
