import {
  AccountCircle,
  Google,
  Key,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Alert,
  Button,
  Divider,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { login } from '../../../services/auth';
import { useDispatch, useSelector } from 'react-redux';
import {
  startLogin,
  updateToken,
  updateUserData,
} from '../../../features/auth/authSlice';
import { DialogResetPassword } from './DialogResetPassword';
import { environment } from '../../../environment/environment';
import { DialogCode } from '../../../components/DialogCode';

export const LoginForm = ({ forgotPassword }) => {
  const [t] = useTranslation('common');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.userData);
  const [tokenTemp, setTokenTemp] = useState('');
  const [numerTry, setNumerTry] = useState(0);
  const [openCodeDialog, setOpenCodeDialog] = useState(false);
  const [passwordView, setPasswordView] = useState(false);
  const [openPasswordReset, setOpenPasswordReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({
    usernameEmail: false,
    password: false,
  });

  const handleClickShowPassword = () => {
    setPasswordView(!passwordView);
  };

  const handleValid = ({ target }) => {
    const value = target.value;
    setErrors({ ...errors, [target.name]: value.length > 0 ? false : true });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false);
    const { usernameEmail, password } = Object.fromEntries(
      new FormData(e.target).entries(),
    );
    if (!usernameEmail || !password) {
      setErrors({
        ...errors,
        usernameEmail: !usernameEmail,
        password: !password,
      });
      return;
    }
    setLoading(true);
    try {
      const response = await login(usernameEmail, password);
      setLoading(false);
      if (!response.ok) {
        setError(true);
        return;
      }
      const { userData, token } = response;
      const { twoFactorAuth } = userData.user;
      if (!twoFactorAuth) {
        localStorage.setItem('token', token);
        dispatch(startLogin({ userData, token }));
      } else {
        setTokenTemp(token);
        dispatch(updateUserData(userData));
        setOpenCodeDialog(true);
      }
    } catch (error) {
      setNumerTry(numerTry + 1);
      setError(true);
      setLoading(false);
    }
  };

  const handleClosedCodeDialog = (value) => {
    setOpenCodeDialog(false);
    if (!value) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return;
    }
    localStorage.setItem('token', tokenTemp);
    dispatch(startLogin({ userData, token: tokenTemp }));
  };

  useEffect(() => {
    if (numerTry >= 3) {
      setOpenPasswordReset(true);
    }
  }, [numerTry]);

  useEffect(() => {
    if (forgotPassword && forgotPassword === 1) {
      setOpenPasswordReset(true);
    }
  }, [forgotPassword]);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: '100%',
          md: '100%',
          lg: '40%',
          xl: '40%',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Stack
        sx={{
          p: {
            xs: 2,
            sm: 2,
            md: 5,
            lg: 5,
            xl: 5,
          },
          maxWidth: '100%',
        }}
        spacing={3}
      >
        <Box
          sx={{
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'flex',
              lg: 'none',
              xl: 'none',
            },
            justifyContent: 'center',
            maxWidth: '100%',
          }}
        >
          <Typography variant='h5' color='primary'>
            Teleima
          </Typography>
          <Typography variant='h5' color='secondary'>
            genologia
          </Typography>
        </Box>
        <h2>{t('login_page.login_button')}</h2>

        <form onSubmit={handleSubmit}>
          <Stack spacing={3} sx={{ width: '100%' }}>
            <FormControl sx={{ width: '100%' }} variant='standard'>
              <InputLabel htmlFor='standard-adornment-password'>
                {t('login_page.email_username_label')}
              </InputLabel>
              <Input
                error={errors.usernameEmail}
                type='text'
                name='usernameEmail'
                onChange={handleValid}
                startAdornment={
                  <InputAdornment position='start'>
                    <AccountCircle />
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl sx={{ width: '100%' }} variant='standard'>
              <InputLabel htmlFor='standard-adornment-password'>
                {t('login_page.password_label')}
              </InputLabel>
              <Input
                error={errors.password}
                name='password'
                type={passwordView ? 'text' : 'password'}
                onChange={handleValid}
                // value={values.password}
                // onChange={handleChange('password')}
                startAdornment={
                  <InputAdornment position='start'>
                    <Key />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                    >
                      {passwordView ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <LoadingButton
                variant='contained'
                type='submit'
                loading={loading}
              >
                <span>{t('login_page.login_button')}</span>
              </LoadingButton>
              <Button
                variant='outlined'
                size='small'
                onClick={() => setOpenPasswordReset(true)}
              >
                {t('login_page.password_lost')}
              </Button>
            </Box>
            {error && <Alert severity='error'>{t('login_page.error')}</Alert>}
          </Stack>
        </form>
        <Divider />
        <Button
          variant='outlined'
          color='contrastTeleimm'
          startIcon={<Google />}
          href={`${environment.apiUrl}/auth/google`}
        >
          {t('login_page.login_google_button')}
        </Button>
        <Button
          variant='contained'
          color='success'
          onClick={() => navigate('/auth/register')}
        >
          {t('login_page.register_button')}
        </Button>
      </Stack>
      <DialogResetPassword
        open={openPasswordReset}
        handleClose={() => {
          setNumerTry(0);
          setOpenPasswordReset(false);
        }}
      />
      <DialogCode
        open={openCodeDialog}
        onClose={handleClosedCodeDialog}
        viewCancel
        customToken={tokenTemp}
      />
    </Box>
  );
};
