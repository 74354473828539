import { CircularProgress, IconButton, List, Typography } from '@mui/material';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BoxEndMessage } from './BoxEndMessage';
import { LoadingListInfinite } from './LoadingListInfinite';
import { NotificationsListItem } from './NotificationsListItem';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useHttp } from '../hooks/useHttp';
import { deleteAllNotifications } from '../services/NotificationsService';
import { AlertReOpen } from './AlertReOpen';
import { useEffect, useState } from 'react';

export const NotificationsList = ({
  notifications = [],
  page,
  totalPages,
  moreData,
  handleDrawerToggle,
  handleUpdateStatus,
  handleClear,
}) => {
  const [t] = useTranslation('common');
  const [openError, setOpenError] = useState(false);
  const { request, loading, error } = useHttp(deleteAllNotifications);
  const handleClearAll = async () => {
    await request();
    handleClear();
  };
  useEffect(() => {
    setOpenError(!!error);
  }, [error]);

  return (
    <>
      <Box
        sx={{
          p: 3,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography variant='subtitle1'>{t('notifications.title')}</Typography>
        {notifications.length > 0 && (
          <IconButton color='error' onClick={handleClearAll}>
            {!loading ? <ClearAllIcon /> : <CircularProgress size={20} />}
          </IconButton>
        )}
      </Box>
      <AlertReOpen
        open={openError}
        severity='error'
        onClose={() => setOpenError(false)}
      />
      <List>
        <InfiniteScroll
          dataLength={notifications.length}
          next={moreData}
          hasMore={page < totalPages}
          loader={<LoadingListInfinite />}
          endMessage={<BoxEndMessage />}
        >
          {notifications.map((notification) => (
            <NotificationsListItem
              key={notification._id}
              {...notification}
              handleDrawerToggle={handleDrawerToggle}
              handleUpdateStatus={handleUpdateStatus}
            />
          ))}
        </InfiniteScroll>
      </List>
    </>
  );
};
