import React from 'react';
import BadgeIcon from '@mui/icons-material/Badge';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import MessageIcon from '@mui/icons-material/Message';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SourceIcon from '@mui/icons-material/Source';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import HelpIcon from '@mui/icons-material/Help';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';

export const menuList = [
  // {
  //   name: 'sidebar.search',
  //   path: '/search',
  //   icon: <SearchIcon />,
  // },
  {
    name: 'sidebar.interpretations',
    path: '/report',
    home: true,
    icon: <SourceIcon />,
  },
  {
    name: 'sidebar.patients',
    path: '/patient',
    home: true,
    icon: <AccountBoxIcon />,
  },
  {
    name: 'sidebar.collaborators',
    path: '/collaborators',
    home: true,
    icon: <BadgeIcon />,
  },
  {
    name: 'sidebar.templates',
    path: '/templates',
    home: true,
    icon: <ChromeReaderModeIcon />,
  },
  {
    name: 'sidebar.friends',
    path: '/friends',
    home: true,
    icon: <PeopleAltIcon />,
  },
  {
    name: 'sidebar.messages',
    path: '/messages',
    home: true,
    icon: <MessageIcon />,
  },
  {
    name: 'sidebar.interpretations_friends',
    path: '/friends/reports',
    home: true,
    icon: <Diversity1Icon />,
  },
  {
    name: 'sidebar.my_edits',
    path: '/friends/reports/editor',
    home: true,
    icon: <DriveFileRenameOutlineIcon />,
  },
  {
    name: 'sidebar.blog',
    path: '/blog',
    home: true,
    icon: <RssFeedIcon />,
  },
  {
    name: 'sidebar.news',
    path: '/news',
    home: true,
    icon: <NewspaperIcon />,
  },
  {
    name: 'sidebar.exports',
    path: '/exports',
    home: true,
    icon: <DataSaverOffIcon />,
  },
  {
    name: 'updates.feedback',
    path: '/feedback',
    home: true,
    icon: <ThumbUpAltIcon />,
  },
  {
    name: 'sidebar.help',
    path: '/help',
    home: true,
    icon: <HelpIcon />,
  },
];
