import { Navigate, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import { LoginPage } from '../pages/Login/LoginPage';
import { AlertMaintenance } from '../components/AlertMaintenance';

const RegisterPage = loadable(() => import('../pages/Register/RegisterPage'), {
  resolveComponent: (components) => components.RegisterPage,
});

const RegisterCodePage = loadable(
  () => import('../pages/RegisterCode/RegisterCodePage'),
  {
    resolveComponent: (components) => components.RegisterCodePage,
  },
);

const ResetPasswordPage = loadable(
  () => import('../pages/ResetPassword/ResetPasswordPage'),
  {
    resolveComponent: (components) => components.ResetPasswordPage,
  },
);

export const AuthRoutes = () => {
  return (
    <>
      <AlertMaintenance fixed />
      <Routes>
        <Route path='/login' element={<LoginPage />} />
        <Route path='/register' element={<RegisterPage />} />
        <Route path='/register/code' element={<RegisterCodePage />} />
        <Route path='/reset/password' element={<ResetPasswordPage />} />
        <Route path='*' element={<Navigate to='/auth/login' replace />} />
      </Routes>
    </>
  );
};
