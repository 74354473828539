import loadable from '@loadable/component';
import { Navigate, Route, Routes } from 'react-router-dom';
import { WrapSubcription } from '../WrapSubcription';

const HomePage = loadable(() => import('../../pages/Home/HomePage'), {
  resolveComponent: (components) => components.HomePage,
});
const ReportView = loadable(() => import('../../pages/Report/ReportView'), {
  resolveComponent: (components) => components.ReportView,
});
const CreateUpdateReport = loadable(
  () => import('../../pages/CreateUpdateReport/CreateUpdateReport'),
  {
    resolveComponent: (components) => components.CreateUpdateReport,
  },
);
const ContentReportPage = loadable(
  () => import('../../pages/ContentReport/ContentReportPage'),
  {
    resolveComponent: (components) => components.ContentReportPage,
  },
);
const CloudPage = loadable(() => import('../../pages/Cloud/CloudPage'), {
  resolveComponent: (components) => components.CloudPage,
});

export const ReportRoutes = () => {
  return (
    <Routes>
      <Route path='/all' element={<HomePage />} />
      <Route path='create' element={<CreateUpdateReport />} />
      <Route
        path='edit/:id'
        element={
          <WrapSubcription>
            <CreateUpdateReport />
          </WrapSubcription>
        }
      />
      <Route path='edit/content/:id' element={<ContentReportPage />} />
      <Route path='cloud/:id' element={<CloudPage />} />
      <Route path='view/:id' element={<ReportView />} />
      <Route path='/*' element={<Navigate to='all' />} />
    </Routes>
  );
};
