import { useEffect } from 'react';
import loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PrivateRoutes } from './PrivateRoutes';
import { PublicRoutes } from './PublicRoutes';
import { useTranslation } from 'react-i18next';
import { AuthRoutes } from './AuthRoutes';
import { DashboardRoutes } from './DashboardRoutes';
import { startCheckingAuth } from '../actions/auth';
import { checkingFinish } from '../features/auth/authSlice';
import { ReportPublicRoutes } from './ReportPublicRoutes';
import { useHttp } from '../hooks/useHttp';
import { getLasMaintenance } from '../services/MaintenanceService';
import moment from 'moment/moment';
import { setDataMaintenance } from '../features/maintenance/maintenanceSlice';

const DownloadFiles = loadable(() => import('../components/DownloadFiles'), {
  resolveComponent: (components) => components.DownloadFiles,
});

const BoxLoadingGlobal = loadable(
  () => import('../components/BoxLoadingGlobal'),
  {
    resolveComponent: (components) => components.BoxLoadingGlobal,
  },
);

const MaintenancePage = loadable(
  () => import('../pages/Maintenance/MaintenancePage'),
  {
    resolveComponent: (components) => components.MaintenancePage,
  },
);

export const AppRouter = () => {
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation('common');
  const { maintenance } = useSelector((state) => state.maintenance);
  const { checking, token, userData } = useSelector((state) => state.auth);
  const { request } = useHttp(getLasMaintenance);
  const statusUser = userData?.status;
  const { error } = useSelector((state) => state.uiErrors);

  const handleUpdateLng = () => {
    if (!Boolean(userData)) return;
    if (!userData.lng) return;
    i18n.changeLanguage(userData.lng);
    document.documentElement.lang = userData.lng;
  };

  const handleMaintenance = async () => {
    const result = await request();
    if (!result) return;
    if (!result.maintenance) return;
    const maintenance = result.maintenance;
    const startLocal = moment(maintenance.startTime).local();
    maintenance.startTime = startLocal.toDate();
    const endLocal = moment(maintenance.endTime).local();
    maintenance.endTime = endLocal.toDate();
    dispatch(
      setDataMaintenance({
        ...maintenance,
        maintenance:
          startLocal.isSameOrBefore(moment()) &&
          endLocal.isSameOrAfter(moment()),
      }),
    );
  };

  useEffect(() => {
    handleMaintenance();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(startCheckingAuth());
    } else {
      dispatch(checkingFinish());
    }
  }, [dispatch]);

  useEffect(() => {
    handleUpdateLng();
  }, [userData]);

  if (checking) {
    return <BoxLoadingGlobal />;
  }

  if (maintenance) {
    return <MaintenancePage />;
  }
  return (
    <BrowserRouter>
      <DownloadFiles />
      <Routes>
        <Route path='/public/*' element={<ReportPublicRoutes />} />
        <Route
          path='/auth/*'
          element={
            <PublicRoutes isLoggedIn={!!token} statusUser={statusUser}>
              <AuthRoutes />
            </PublicRoutes>
          }
        />
        <Route
          path='/*'
          element={
            <PrivateRoutes isLoggedIn={!!token} statusUser={statusUser}>
              <DashboardRoutes />
            </PrivateRoutes>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
