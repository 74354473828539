import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  Box,
} from '@mui/material';
import CloudIcon from '@mui/icons-material/Cloud';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTimePass } from '../hooks/useTimePass';
import { NotificationsTypes } from '../types/Notifications';
import { useHttp } from '../hooks/useHttp';
import { markViewedNotification } from '../services/NotificationsService';

const { friendRequest, friendRequestConfirm, message, cloudFolder } =
  NotificationsTypes;

const textNotification = (kind) => {
  switch (kind) {
    case friendRequest:
      return 'notifications.friend_request.title';
    case friendRequestConfirm:
      return 'notifications.friend_request_confirm.title';
    case message:
      return 'notifications.message.title';
    case cloudFolder:
      return 'notifications.cloud_folder.message';
    default:
      return '';
  }
};

export const NotificationsListItem = ({
  _id,
  kind,
  status,
  from = {},
  timestamp,
  extraInfo = {},
  handleDrawerToggle,
  handleUpdateStatus,
}) => {
  const [t, i18n] = useTranslation('common');
  const navigate = useNavigate();
  const { name, last_name, photo, username } = from;
  const time = useTimePass(i18n.language, timestamp);
  const { request } = useHttp(markViewedNotification, { id: _id });
  const { reportId = '', folderName = '', path = '' } = extraInfo;

  const handleUpateStatus = async () => {
    await request();
    handleUpdateStatus(_id, true);
  };

  const handleClick = () => {
    handleUpateStatus();
    if (kind === friendRequestConfirm || kind === friendRequest) {
      navigate(`/friends/${username}`);
      handleDrawerToggle();
      return;
    }
    if (kind === message) {
      navigate(`/messages/user/${username}`);
      handleDrawerToggle();
      return;
    }

    if (kind === cloudFolder) {
      navigate(`/report/cloud/${reportId}?path=${path}`);
      handleDrawerToggle();
      return;
    }
  };

  return (
    <ListItem
      alignItems='flex-start'
      secondaryAction={
        !status && <CircleIcon fontSize='small' color='primary' />
      }
    >
      <ListItemButton onClick={() => handleClick()}>
        <ListItemAvatar>
          {NotificationsTypes.cloudFolder === kind ? (
            <CloudIcon />
          ) : (
            <Avatar alt={name} src={photo} />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={
            NotificationsTypes.cloudFolder === kind
              ? t('notifications.cloud_folder.title')
              : `${name} ${last_name}`
          }
          secondary={
            <Box
              component='span'
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{ display: 'inline' }}
                component='span'
                variant='body2'
                color='text.primary'
              >
                {t(textNotification(kind), { value: folderName })}
              </Typography>
              <Typography
                sx={{ display: 'inline' }}
                component='span'
                variant='caption'
                color='text.primary'
              >
                {time}
              </Typography>
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
