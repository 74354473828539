import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  termSearch: '',
  dataSearch: null,
  dateStart: null,
  dateEnd: null,
  sectionSearch: '',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setTermSearch: (state, action) => {
      return {
        ...state,
        termSearch: action.payload,
      };
    },
    setDataSearch: (state, action) => {
      return {
        ...state,
        dataSearch: action.payload,
      };
    },
    setDateStart: (state, action) => {
      return {
        ...state,
        dateStart: action.payload,
      };
    },
    setDateEnd: (state, action) => {
      return {
        ...state,
        dateEnd: action.payload,
      };
    },
    cleanDateStart: (state) => {
      return {
        ...state,
        dateStart: null,
      };
    },
    cleanDateEnd: (state) => {
      return {
        ...state,
        dateEnd: null,
      };
    },
    cleanDataSearch: (state) => {
      return {
        ...state,
        dataSearch: null,
      };
    },
    cleanTermSearch: (state) => {
      return {
        ...state,
        termSearch: '',
      };
    },
    cleanAllSearch: () => {
      return initialState;
    },
    setSectionSearch: (state, action) => {
      return {
        ...state,
        sectionSearch: action.payload,
      };
    },
    cleanSectionSearch: (state) => {
      return {
        ...state,
        sectionSearch: '',
      };
    },
  },
});

export const {
  setTermSearch,
  setDataSearch,
  cleanDataSearch,
  cleanTermSearch,
  cleanAllSearch,
  setSectionSearch,
  cleanSectionSearch,
  setDateStart,
  setDateEnd,
  cleanDateStart,
  cleanDateEnd,
} = searchSlice.actions;
export default searchSlice.reducer;
