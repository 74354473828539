import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const WrapSubcription = ({ children }) => {
  const statusSubscription = useSelector(
    (state) => state.auth.userData.user.statusSubscription,
  );

  return statusSubscription === 'Active' ? (
    children
  ) : (
    <Navigate to='/settings/subscription' />
  );
};
