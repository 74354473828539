import loadable from '@loadable/component';
import { Navigate, Route, Routes } from 'react-router-dom';

const FriendsPage = loadable(() => import('../../pages/Friends/FriendsPage'), {
  resolveComponent: (components) => components.FriendsPage,
});

const FriendProfilePage = loadable(
  () => import('../../pages/FriendProfile/FriendProfilePage'),
  {
    resolveComponent: (components) => components.FriendProfilePage,
  },
);

const SearchFriendsPage = loadable(
  () => import('../../pages/SearchFriends/SearchFriendsPage'),
  {
    resolveComponent: (components) => components.SearchFriendsPage,
  },
);

const FriendsReportsPage = loadable(
  () => import('../../pages/FriendsReports/FriendsReportsPage'),
  {
    resolveComponent: (components) => components.FriendsReportsPage,
  },
);

export const FriendsRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<FriendsPage />} />
      <Route path='/reports' element={<FriendsReportsPage />} />
      <Route
        path='/reports/editor'
        element={<FriendsReportsPage onlyEditor />}
      />
      <Route path='/:username' element={<FriendProfilePage />} />
      <Route path='/search' element={<SearchFriendsPage />} />
      <Route path='/*' element={<Navigate to='/' />} />
    </Routes>
  );
};
