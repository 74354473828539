import { createSlice } from '@reduxjs/toolkit';
const ininitalState = { files: [], open: false };
export const DownloadsFilesSlice = createSlice({
  name: 'downloadsFiles',
  initialState: ininitalState,
  reducers: {
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    addFile: (state, action) => {
      state.files.push(action.payload);
    },
    removeFile: (state, action) => {
      state.files = state.files.filter((file) => file.id !== action.payload);
    },
    resetFiles: () => ininitalState,
  },
});

export const { setFiles, setOpen, addFile, removeFile, resetFiles } =
  DownloadsFilesSlice.actions;

export default DownloadsFilesSlice.reducer;
