import moment from 'moment';
import { dateCountry } from './dateCountry';
import 'moment/locale/es-mx';
import 'moment/locale/fr-ch';
import 'moment/locale/pt-br';
export const countTime = (lng, value) => {
  if (!value) return '';
  const time = moment().diff(value, 'days');
  if (time > 7) {
    return dateCountry(lng, value);
  }

  switch (lng) {
    case 'es':
      return moment(value).locale('es-mx').startOf('minutes').fromNow();
    case 'en':
      return moment(value).startOf('minutes').fromNow();
    case 'fr':
      return moment(value).locale('fr-ch').startOf('minutes').fromNow();
    case 'pt':
      return moment(value).locale('pt-br').startOf('minutes').fromNow();
    default:
      return moment(value).startOf('minutes').fromNow();
  }
};
