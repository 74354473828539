import { useState } from 'react';

export const useHttp = (requestFunction, paramsRequest = {}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = async (params = {}) => {
    setLoading(true);
    try {
      setError(null);
      const { data } = await requestFunction({ ...paramsRequest, ...params });
      setLoading(false);
      return data;
    } catch (e) {
      setLoading(false);
      setError({
        code: e.code,
        response: e.response,
      });
    }
  };

  const clearError = () => {
    setError(null);
  };

  return { loading, request, error, clearError };
};
