import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import uiErrorsReducer from '../features/ui/uiSlice';
import cloudReducer from '../features/cloud/cloudSlice';
import filesReducer from '../features/files/filesSlice';
import searchReducer from '../features/search/searchSlice';
import cloudSpaceReducer from '../features/cloudSpace/cloudSpaceSlice';
import maintenanceReducer from '../features/maintenance/maintenanceSlice';
import downloadsFilesReducer from '../features/DownloadsFiles/DownloadsFilesSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    uiErrors: uiErrorsReducer,
    cloud: cloudReducer,
    files: filesReducer,
    search: searchReducer,
    cloudSpace: cloudSpaceReducer,
    maintenance: maintenanceReducer,
    downloadsFiles: downloadsFilesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
