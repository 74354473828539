import { createSlice } from '@reduxjs/toolkit';

const initalState = { pathActive: null, reportActive: null };

export const cloudSlice = createSlice({
  name: 'cloud',
  initialState: initalState,
  reducers: {
    setReportActive: (state, action) => {
      return { ...state, reportActive: action.payload };
    },
    setPathActive: (state, action) => {
      return { ...state, pathActive: action.payload };
    },
    removeReportActive: (state) => {
      return { ...state, reportActive: null };
    },
    removePathActive: (state) => {
      return { ...state, pathActive: null };
    },
  },
});

export const {
  setReportActive,
  setPathActive,
  removeReportActive,
  removePathActive,
} = cloudSlice.actions;
export default cloudSlice.reducer;
