export const configTheme = (prefersDarkMode = false) => ({
	palette: {
		mode: prefersDarkMode ? 'dark' : 'light',
		...(prefersDarkMode
			? {
					primary: {
						light: '#335f9b',
						main: '#1976d2',
						dark: '#003882',
						contrastText: '#fff',
					},
					secondary: {
						light: '#71b933',
						main: '#4EA800',
						dark: '#367500',
						// contrastText: '#fff',
					},
					contrastTeleimm: {
						light: '#f78b33',
						main: '#F66F00',
						dark: '#ac4d00',
					},
					infoTeleimm: {
						light: '#99afcd',
						main: '#809CC1',
						dark: '#596d87',
					},
					infoSecondaryTeleimm: {
						light: '#b9dc98',
						main: '#A8D47F',
						dark: '#759458',
					},
			  }
			: {
					primary: {
						light: '#335f9b',
						main: '#003882',
						dark: '#00275b',
						contrastText: '#fff',
					},
					secondary: {
						light: '#71b933',
						main: '#4EA800',
						dark: '#367500',
						// contrastText: '#fff',
					},
					contrastTeleimm: {
						light: '#f78b33',
						main: '#F66F00',
						dark: '#ac4d00',
					},
					infoTeleimm: {
						light: '#99afcd',
						main: '#809CC1',
						dark: '#596d87',
					},
					infoSecondaryTeleimm: {
						light: '#b9dc98',
						main: '#A8D47F',
						dark: '#759458',
					},
			  }),
	},
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					background: '#003882',
				},
			},
		},
	},
});
