import { useMemo, useEffect, useState } from 'react';
import io from 'socket.io-client';

export const useSocket = (urlServer) => {
  const socket = useMemo(
    () => io.connect(urlServer, { transports: ['websocket'] }),
    [urlServer],
  );
  const [online, setOnline] = useState(false);

  useEffect(() => {
    setOnline(socket.connected);
  }, [socket]);

  useEffect(() => {
    socket.on('connect', () => {
      setOnline(true);
    });
  }, [socket]);

  useEffect(() => {
    socket.on('disconnect', () => {
      setOnline(false);
    });
  }, [socket]);

  return {
    socket,
    online,
  };
};
