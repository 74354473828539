import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactInputVerificationCode from 'react-input-verification-code';
import { useHttp } from '../hooks/useHttp';
import { verifyTwofa } from '../services/auth';
import { AlertReOpen } from './AlertReOpen';
const defaultMessagesError = 'reusable.general_error';
const errorCodeInvalid = 'two_factor.invalid_code';

export const DialogCode = ({
  open,
  onClose = () => {},
  viewCancel = false,
  customToken = '',
}) => {
  const [t] = useTranslation('common');
  const [code, setCode] = useState('');
  const [openError, setOpenError] = useState(false);
  const [messageError, setMessageError] = useState(defaultMessagesError);
  const { request, loading, error } = useHttp(verifyTwofa, {
    code,
    token: customToken,
  });
  const handleVerify = async () => {
    setOpenError(false);
    const result = await request();
    if (!result) return;
    const verify = result.verify;
    if (verify) onClose(true);
  };
  const handleClose = (value = false) => onClose(value);

  useEffect(() => {
    if (Boolean(error)) {
      const message = error.response.data.message;
      const messageError =
        message === 'Invalid code' ? errorCodeInvalid : defaultMessagesError;
      setCode('');
      setMessageError(messageError);
      setOpenError(true);
    }
  }, [error]);

  useEffect(() => {
    if (code.length === 6) handleVerify();
  }, [code]);

  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
        }}
      >
        {viewCancel && (
          <Box sx={{ p: 1 }}>
            <IconButton color='error' onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        <DialogContentText
          sx={{
            textAlign: 'center',
          }}
        >
          {t('two_factor.insert_code')}
        </DialogContentText>
        <ReactInputVerificationCode
          length={6}
          placeholder=''
          autoFocus={true}
          value={code}
          onChange={(value) => setCode(value)}
        />
      </DialogContent>
      <Box
        sx={{
          p: 2,
        }}
      >
        <AlertReOpen
          open={openError}
          onClose={() => setOpenError(false)}
          message={t(messageError)}
          severity='error'
        />
      </Box>
      <DialogActions
        sx={{
          justifyContent: 'center',
          p: 2,
          gap: 2,
        }}
      >
        {code.length > 0 && (
          <Button
            className='animate__animated animate__fadeIn'
            onClick={() => setCode('')}
          >
            {t('reusable.clean')}
          </Button>
        )}
        <LoadingButton
          size='small'
          variant='contained'
          loading={loading}
          onClick={() => handleVerify()}
          disabled={code.length < 6}
        >
          {t('two_factor.valid_code')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
