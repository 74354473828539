import {
  checkingFinish,
  logout,
  startLogin,
  updateUserData,
} from '../features/auth/authSlice';
import { startServerError } from '../features/ui/uiSlice';
import { renew } from '../services/auth';

export const startLogout = () => {
  return (dispath) => {
    localStorage.removeItem('token');
    localStorage.removeItem('userdata');
    dispath(logout());
  };
};

export const updatePhotoUser = (urlImage) => {
  return (dispath, getState) => {
    const { auth } = getState();
    const userData = { ...auth.userData };
    const user = { ...userData.user };
    user.photo = urlImage;
    dispath(updateUserData({ ...userData, user }));
  };
};

export const addWwWl = (value) => {
  return (dispath, getState) => {
    const { auth } = getState();
    const userData = { ...auth.userData };
    if (!userData.wwWl.find(({ _id }) => _id === value._id)) {
      userData.wwWl.push(value);
      dispath(updateUserData(userData));
    }
  };
};

export const updateWwWlItem = (value) => {
  return (dispath, getState) => {
    const { auth } = getState();
    const userData = { ...auth.userData };
    userData.wwWl = userData.wwWl.map((item) => {
      if (item._id !== value._id) return item;
      return value;
    });
    dispath(updateUserData(userData));
  };
};

export const removeWwWl = (id) => {
  return (dispath, getState) => {
    const { auth } = getState();
    const userData = { ...auth.userData };
    userData.wwWl = userData.wwWl.filter(({ _id }) => _id !== id);
    dispath(updateUserData(userData));
  };
};

export const updateLng = (lng) => {
  return (dispath, getState) => {
    const { auth } = getState();
    const userData = { ...auth.userData };
    userData.lng = lng;
    dispath(updateUserData(userData));
  };
};

export const updateLabelsInterpretation = (labels) => {
  return (dispath, getState) => {
    const { auth } = getState();
    const userData = { ...auth.userData };
    userData.interpretation = labels;
    dispath(updateUserData(userData));
  };
};

export const updateLabelsExtra = (labels) => {
  return (dispath, getState) => {
    const { auth } = getState();
    const userData = { ...auth.userData };
    userData.extra = labels;
    dispath(updateUserData(userData));
  };
};

export const updateTimeZoneAndCountry = (timeZone, country) => {
  return (dispath, getState) => {
    const { auth } = getState();
    const userData = { ...auth.userData };
    const user = { ...userData.user };
    user.timeZone = timeZone;
    user.country = country;
    dispath(updateUserData({ ...userData, user }));
  };
};

export const updateNameUser = (name, last_name, speciality) => {
  return (dispath, getState) => {
    const { auth } = getState();
    const userData = { ...auth.userData };
    const user = { ...userData.user };
    user.name = name;
    user.last_name = last_name;
    user.speciality = speciality;
    dispath(updateUserData({ ...userData, user }));
  };
};

export const startCheckingAuth = () => {
  return async (dispatch) => {
    try {
      const response = await renew();
      if (response.ok) {
        const { userData, token } = response;
        dispatch(startLogin({ userData, token }));
        dispatch(updateTwoAuthValid(true));
      } else {
        dispatch(checkingFinish());
      }
    } catch (error) {
      dispatch(checkingFinish());
      if (!String(error).includes(401)) dispatch(startServerError(error));
    }
  };
};
