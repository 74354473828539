import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const BoxEndMessage = ({ title = 'interpretation.total_reports' }) => {
  const [t] = useTranslation('common');
  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Typography variant='body1' gutterBottom component='div' align='center'>
        {t(title)}
      </Typography>
    </Box>
  );
};
