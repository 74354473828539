import { useEffect } from 'react';
import { Box } from '@mui/material';

export const AdsComponent = ({ dataAdSlot }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {}
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <ins
        className='adsbygoogle ads'
        data-ad-client='ca-pub-4778847891254601'
        data-ad-slot={dataAdSlot}
      ></ins>
    </div>
  );
};
