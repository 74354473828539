import { CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const LoadingBox = ({ isPage = true, sx = {} }) => {
  const [t] = useTranslation('common');

  if (isPage)
    return (
      <Box
        className='animate__animated animate__fadeIn'
        sx={{ m: 'auto', width: '100%', ...sx }}
        display='flex'
        justifyContent='center'
        flexDirection='column'
        alignItems='center'
        minHeight='80vh'
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      className='animate__animated animate__fadeIn'
      sx={{ m: 'auto', width: '100%', ...sx }}
      display='flex'
      justifyContent='center'
      flexDirection='column'
      alignItems='center'
      minHeight='50vh'
    >
      <CircularProgress size={50} />
      <Typography variant='h6'>{t('reusable.loading')}</Typography>
    </Box>
  );
};
