import { environment } from '../environment/environment';
import { headersAutorization } from '../helpers/headerAutorization';
import http from '../helpers/http';

const url = environment.apiUrl;

export const getNotifications = ({ page }) => {
  const headers = headersAutorization();
  return http.get(`${url}/notifications?page=${page}`, headers);
};

export const markViewedNotification = (id) => {
  const headers = headersAutorization();
  return http.put(`${url}/notifications/view`, { id }, headers);
};

export const deleteAllNotifications = () => {
  const headers = headersAutorization();
  return http.remove(`${url}/notifications/clean`, headers);
};
