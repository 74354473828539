import { createSlice } from '@reduxjs/toolkit';
import { typeErrorLocation } from '../../types/types';

const ininitalState = { type: '', error: null, isBottom: false };

export const uiErrors = createSlice({
  name: 'uiErrors',
  initialState: ininitalState,
  reducers: {
    startDeviceError: (_, action) => {
      return {
        type: typeErrorLocation.device,
        error: action.payload,
      };
    },
    clearDeviceError: (_, action) => {
      return {
        type: typeErrorLocation.device,
        error: null,
      };
    },
    startServerError: (_, action) => {
      return {
        type: typeErrorLocation.server,
        error: action.payload,
      };
    },
    clearServerError: () => {
      return {
        type: typeErrorLocation.server,
        error: null,
      };
    },
    setIsBottom: (state, action) => {
      return {
        ...state,
        isBottom: action.payload,
      };
    },
  },
});

export const {
  startDeviceError,
  clearDeviceError,
  startServerError,
  clearServerError,
  setIsBottom,
} = uiErrors.actions;

export default uiErrors.reducer;
