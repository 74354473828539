import { createSlice } from '@reduxjs/toolkit';

const initalState = {
  checking: true,
  token: '',
  twoAuthValid: false,
  userData: {},
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initalState,
  reducers: {
    startLogin: (_, action) => {
      return {
        ...action.payload,
        checking: false,
      };
    },
    logout: () => {
      return { checking: false, token: '', userData: {} };
    },
    updateToken(state, action) {
      state.token = action.payload;
      return state;
    },
    updateUserData: (state, action) => {
      return {
        ...state,
        userData: {
          ...action.payload,
        },
      };
    },
    checkingFinish: (state) => {
      return {
        ...state,
        checking: false,
      };
    },
    updateOnlineStatus: (state, action) => {
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            online: action.payload,
          },
        },
      };
    },
    updateTwoAuthValid: (state, action) => {
      return {
        ...state,
        twoAuthValid: action.payload,
      };
    },
    updateTwoFactorAuth: (state, action) => {
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            twoFactorAuth: action.payload,
          },
        },
      };
    },
    updateRfcUser: (state, action) => {
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            rfc: action.payload,
          },
        },
      };
    },
    updateFiscalDocument: (state, action) => {
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            fiscalDocument: action.payload,
          },
        },
      };
    },
    updateCfdi: (state, action) => {
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            useCfdi: action.payload,
          },
        },
      };
    },
  },
});

export const {
  startLogin,
  logout,
  updateUserData,
  checkingFinish,
  updateOnlineStatus,
  updateTwoFactorAuth,
  updateTwoAuthValid,
  updateToken,
  updateRfcUser,
  updateFiscalDocument,
  updateCfdi,
} = authSlice.actions;
export default authSlice.reducer;
