import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: {
    es: '',
    en: '',
    pt: '',
    fr: '',
  },
  description: {
    es: '',
    en: '',
    pt: '',
    fr: '',
  },
  startTime: null,
  endTime: null,
  maintenance: false,
};

export const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {
    setDataMaintenance: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    cleanDataMaintenance: () => {
      return initialState;
    },
    setMaintenanceStatus: (state, action) => {
      return {
        ...state,
        maintenance: action.payload,
      };
    },
  },
});

export const {
  setDataMaintenance,
  cleanDataMaintenance,
  setMaintenanceStatus,
} = maintenanceSlice.actions;
export default maintenanceSlice.reducer;
