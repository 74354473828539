import { environment } from '../environment/environment';
import { headersAutorization } from '../helpers/headerAutorization';
import http from '../helpers/http';

const url = environment.apiUrl;

export const login = async (usernameEmail, password) => {
  const { data } = await http.post(
    `${url}/login`,
    {
      username_email: usernameEmail,
      password,
      remember: true,
    },
    {},
    {
      withCredentials: true,
    },
  );

  const userData = { ...data };
  delete userData.ok;
  delete userData.token;
  return {
    ok: true,
    userData,
    token: data.token,
  };
};

export const renew = async () => {
  const headers = headersAutorization();
  const { data } = await http.get(`${url}/login/renew`, headers, {
    withCredentials: true,
  });
  const userData = { ...data };
  delete userData.ok;
  delete userData.token;
  localStorage.setItem('token', data.token);
  localStorage.setItem('userdata', JSON.stringify(userData));
  return {
    ok: true,
    userData,
    token: data.token,
  };
};

export const logoutUser = () => {
  const headers = headersAutorization();
  return http.post(`${url}/logout`, {}, headers);
};

export const verifyTwofa = ({ code, token = '' }) => {
  const headers = headersAutorization(token);
  return http.post(`${url}/auth/verify2fa`, { code }, headers);
};

export const closeAllSessions = () => {
  const headers = headersAutorization();
  return http.post(`${url}/auth/close/sessions`, {}, headers);
};
