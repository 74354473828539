import moment from 'moment';
import 'moment/dist/locale/es';
import 'moment/dist/locale/es-mx';
import 'moment/dist/locale/fr-ch';
import 'moment/dist/locale/pt-br';

const getLocale = (lng) => {
  switch (lng) {
    case 'es':
      return 'es';
    case 'fr':
      return 'fr-ch';
    case 'pt':
      return 'pt-br';
    default:
      return '';
  }
};

export const dateCountry = (lng, value) => {
  const format = 'LLL';
  moment.locale(getLocale(lng));
  return moment(value).format(format);
};
